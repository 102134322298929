import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Select from 'react-select'

import styled from 'styled-components'

import { setNotificationState } from '../../../redux/notificationsReducer'
import GovernanceMetadataAdd from '../Formats/Format1/MetadataAdd'
import GovernanceMetadataEdit from '../Formats/Format1/MetadataEdit'
import GovernanceExportModal from './ExportModal'

import {
  setSelectedGovernance,
  setFileUploaded,
} from '../../../redux/governanceReducer'

import {
  handleMetadataInjection1_0,
  handleRolesInjection1_0,
  handleSchemasInjection1_0,
  handleParticipantsInjection1_0,
  handleParticipantsMetadataInjection1_0,
} from '../Formats/Format1/DataInjection1'

import { handleParticipantsInjection2_0 } from '../Formats/Format2/DataInjection2'

import {
  handleMetadataExtraction1_0,
  handleSchemasExtraction1_0,
  handleParticipantsExtraction1_0,
  handleRolesExtraction1_0,
} from '../Formats/Format1/DataExtraction1'

import { H3 } from '../../../UI/CommonStyles'
import PageHeader from '../../../UI/PageHeader'
import PageSection from '../../../UI/PageSection'
import {
  AttributeTable,
  AttributeRow,
  // IconHelp,
} from '../Styles/CommonStylesTables'

const GovernanceHeader = styled.h3`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
`
const EditBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  margin: 5px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const ExportBtn = styled.button`
  width: 140px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  margin: 5px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const Input = styled.input``
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`
const SubmitFormBtn = styled.button``

function Governance(props) {
  const dispatch = useDispatch()
  const governanceState = useSelector((state) => state.governance)

  const [selectedFile, setSelectedFile] = useState({})

  const [editMetadataModalIsOpen, setEditMetadataModalIsOpen] = useState(false)
  const closeEditMetadataModal = () => setEditMetadataModalIsOpen(false)

  const [addMetadataModalIsOpen, setAddMetadataModalIsOpen] = useState(false)
  const closeAddMetadataModal = () => setAddMetadataModalIsOpen(false)

  const [
    governanceExportModalIsOpen,
    setGovernanceExportModalIsOpen,
  ] = useState(false)
  const closeGovernanceExportModal = () => setGovernanceExportModalIsOpen(false)

  // (eldersonar) This is an example of storing all possible piblish options. Only API options is supported as of 5/18/2023
  const publishOptions = ['PROVEN API', 'AS3']

  const governanceForm = useRef(null)
  const governancePath = useRef(null)

  const uploadFormat1_0 = useCallback(
    (file) => {
      handleMetadataInjection1_0(file, dispatch)
      handleRolesInjection1_0(file, dispatch)
      handleSchemasInjection1_0(file, dispatch)
      handleParticipantsInjection1_0(file, dispatch)
      handleParticipantsMetadataInjection1_0(file, dispatch)
    },
    [dispatch]
  )

  const uploadFormat2_0 = useCallback(
    (file) => {
      handleMetadataInjection1_0(file, dispatch)
      handleRolesInjection1_0(file, dispatch)
      handleSchemasInjection1_0(file, dispatch)
      handleParticipantsInjection2_0(file, dispatch)
      handleParticipantsMetadataInjection1_0(file, dispatch)
    },
    [dispatch]
  )

  const handleDataInjection = useCallback(() => {
    // const handleDataInjection = () => {
    if (
      governanceState.selectedGovernance &&
      governanceState.selectedGovernance.format === '1.0'
    ) {
      uploadFormat1_0(governanceState.selectedGovernance)
    } else if (
      governanceState.selectedGovernance &&
      governanceState.selectedGovernance.format === '2.0'
    ) {
      uploadFormat2_0(governanceState.selectedGovernance)
    } else {
      // (eldersonar) Do we want to reject unsupported file formats or just inject data based on format 1.0?
      dispatch(
        setNotificationState({
          message: `Uploading a file that is not governance, or governance format is not supported`,
          type: 'error',
        })
      )
    }
    //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
    //(RomanStepanyan) This state can potentinally be replaced with more optimal code
    dispatch(setFileUploaded(false))
  }, [governanceState, dispatch, uploadFormat1_0, uploadFormat2_0])

  useEffect(() => {
    if (
      governanceState.fileUploaded &&
      governanceState.selectedGovernance &&
      Object.keys(governanceState.selectedGovernance).length !== 0 &&
      Object.getPrototypeOf(governanceState.selectedGovernance) ===
        Object.prototype
    ) {
      handleDataInjection()
    }
    setSelectionEvent(false)
  }, [
    governanceState.selectedGovernance,
    governanceState.fileUploaded,
    handleDataInjection,
  ])

  const addMetadata = () => {
    setAddMetadataModalIsOpen(true)
  }

  const editMetadata = () => {
    setEditMetadataModalIsOpen(true)
  }

  // (eldersonar) This function alows the governance modal to open and present all possible export options descirbed in publishOptions array
  const openGovernanceExport = () => {
    setGovernanceExportModalIsOpen(true)
  }

  // (eldersonar) This state allows us to control the value of the governance selection (from files)
  const [selectedFileHTML, setSelectedFileHTML] = useState(null)

  let governanceSelectHandler = (event) => {
    const file = event.target.files[0]

    // (eldersonar) Save HTML element to a local state
    setSelectedFileHTML(event.target)

    if (file) {
      const fileReader = new FileReader()
      fileReader.readAsText(event.target.files[0], 'UTF-8')
      fileReader.onload = (e) => {
        try {
          setSelectedFile(JSON.parse(e.target.result))

          dispatch(
            setNotificationState({
              message: `The governance file, ${file.name}, has been selected. Next, click on "Upload".`,
              type: 'notice',
            })
          )
        } catch (error) {
          dispatch(
            setNotificationState({
              message: `Invalid JSON file: ${file.name} contains syntax errors`,
              type: 'error',
            })
          )
          document.getElementById('input-choose-file').value = ''
        }
      }
    }
  }

  // (eldersonar) This is a simple POST request to upload a file to a remote server
  const exportGovernanceFile = async () => {
    // --------------------------OLD API FLOW--------------------------------
    // // (eldersonar) Fetch extracted governance file
    // const file = extractGovernance()
    // // Handle JSON file download
    // if (file) {
    //   if (governanceState.participantsMetadata.author !== 'DID not anchored') {
    //     try {
    //       const response = await Axios({
    //         method: 'post',
    //         url: 'https://governance-file-hosting.glitch.me/file',
    //         data: file,
    //       })
    //       console.log(response.data)
    //     } catch (error) {
    //       console.error(error)
    //     }
    //     dispatch(
    //       setNotificationState({
    //         message:
    //           'Selected governance file was succesfully exported to a hosting service',
    //         type: 'notice',
    //       })
    //     )
    //   } else {
    //     dispatch(
    //       setNotificationState({
    //         message: 'Publishing without public DID is forbidden',
    //         type: 'error',
    //       })
    //     )
    //   }
    // } else {
    //   dispatch(
    //     setNotificationState({
    //       message: `Governance file is not selected, or the governance format is not supported`,
    //       type: 'error',
    //     })
    //   )
    // }
    // --------------------------OLD API FLOW--------------------------------
    publishLocally()
  }

  // (eldersonar) This is a simple POST request to upload a file to a remote server
  const exportGovernanceFileES3 = async () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()

    // Handle JSON file download
    if (file) {
      props.sendRequest('GOVERNANCE', 'AS3_EXPORT', {
        file,
      })
    } else {
      dispatch(
        setNotificationState({
          message: `Governance file is not selected, or the governance format is not supported`,
          type: 'error',
        })
      )
    }
  }

  // (eldersonar) Handle governance injection based on the format type
  const handleGovernanceUpload = async (e) => {
    e.preventDefault()
    // (eldersonar) Clear selection for governance file (from DB)
    setDbGovernance('')
    dispatch(setSelectedGovernance(selectedFile))
    //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
    //(RomanStepanyan) This state can potentinally be replaced with more optimal code
    dispatch(setFileUploaded(true))
  }

  // (eldersonar) Handle goverance file download
  const downloadFile = () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()

    // Handle JSON file download
    if (file) {
      if (governanceState.participantsMetadata.author !== 'DID not anchored') {
        // create file in browser
        const fileName = 'governance-framework-upload-test'
        const json = JSON.stringify(file, null, 2)
        const blob = new Blob([json], { type: 'application/json' })
        const href = URL.createObjectURL(blob)

        // create HTML element with href to file
        const link = document.createElement('a')
        link.href = href
        link.download = fileName + '.json'
        document.body.appendChild(link)
        link.click()

        // clean up element & remove ObjectURL to avoid memory leak
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
        dispatch(
          setNotificationState({
            message: 'Selected governance file is downloaded',
            type: 'notice',
          })
        )
      } else {
        dispatch(
          setNotificationState({
            message: 'Publishing without public DID is forbidden',
            type: 'error',
          })
        )
      }
    } else {
      dispatch(
        setNotificationState({
          message: `Governance file is not selected, or the governance format is not supported`,
          type: 'error',
        })
      )
    }
  }

  const extractGovernance = () => {
    // (eldersonar) Save as UNIX timestamp
    const timestamp = Math.floor(Date.now() / 1000)

    let result = {}

    if (
      governanceState.metadata.format === '1.0' ||
      governanceState.metadata.format === '2.0'
    ) {
      const entries = handleParticipantsExtraction1_0()
      const metadata = handleMetadataExtraction1_0(dispatch)
      const roles = {
        roles: Object.assign({}, ...handleRolesExtraction1_0()),
      }
      const schemas = { schemas: handleSchemasExtraction1_0() }
      const participants = {}

      participants.participants = {
        id: governanceState.participantsMetadata.id
          ? governanceState.participantsMetadata.id
          : 'default_participants_uuid',

        // (eldersonar) This is a temporary solution till the community decides on where the "author" lives
        author: governanceState.metadata.author
          ? governanceState.metadata.author
          : 'DID not anchored',
        created: timestamp,
        version: governanceState.participantsMetadata.version
          ? governanceState.participantsMetadata.version
          : governanceState.metadata.version,
        topic:
          governanceState.participantsMetadata.topic || 'No topic provided',
        entries,
      }

      result = {
        ...metadata,
        ...schemas,
        ...participants,
        ...roles,
      }
      // (eldersonar) This is simulating final result of the governance file
      console.log(result)

      // (eldersonar) TODO: explore canonicalization and signatures

      return result
    } else {
      // (eldersonar) governance format is not supported
      return null
    }
  }

  // (eldersonar) TODO: needs better naming
  const [dbGovernance, setDbGovernance] = useState('')
  // const [appliedGovernance, setAppliedGovernance] = useState('')

  // const [governanceFiles, setGovernanceFiles] = useState(undefined)
  const [governanceOptions, setGovernanceOptions] = useState([])
  const [selectionEvent, setSelectionEvent] = useState(false)

  // (eldersonar) Setting up governance options
  useEffect(() => {
    let options = []

    // (eldersonar) Handle governance options state (needs better description)
    if (governanceState.governanceEditorFiles) {
      for (let i = 0; i < governanceState.governanceEditorFiles.length; i++) {
        options.push({
          id: governanceState.governanceEditorFiles[i].governance_id,
          label: governanceState.governanceEditorFiles[i].published
            ? governanceState.governanceEditorFiles[i].name
                .concat(' version ')
                .concat(governanceState.governanceEditorFiles[i].version)
                .concat(' format ')
                .concat(governanceState.governanceEditorFiles[i].format)
                .concat(' <<published>>')
            : governanceState.governanceEditorFiles[i].name
                .concat(' version ')
                .concat(governanceState.governanceEditorFiles[i].version)
                .concat(' format ')
                .concat(governanceState.governanceEditorFiles[i].format),
          value: governanceState.governanceEditorFiles[i].id,
        })
      }
      setGovernanceOptions(options)
    }
  }, [governanceState.governanceEditorFiles])

  // (eldersonar) Setting up selected governance
  useEffect(() => {
    // (eldersonar) Handle selected governance state
    if (
      governanceState.selectedGovernanceRecord &&
      Object.keys(governanceState.selectedGovernanceRecord).length !== 0 &&
      Object.getPrototypeOf(governanceState.selectedGovernanceRecord) ===
        Object.prototype &&
      selectionEvent
    ) {
      console.log(governanceState.selectedGovernanceRecord)
      setDbGovernance({
        id: governanceState.selectedGovernanceRecord.id,
        // label: governanceState.selectedGovernanceRecord.name
        //   .concat(' version ')
        //   .concat(governanceState.selectedGovernanceRecord.version)
        //   .concat(' format ')
        //   .concat(governanceState.selectedGovernanceRecord.format),
        label: governanceState.selectedGovernanceRecord.published
          ? governanceState.selectedGovernanceRecord.name
              .concat(' version ')
              .concat(governanceState.selectedGovernanceRecord.version)
              .concat(' format ')
              .concat(governanceState.selectedGovernanceRecord.format)
              .concat(' <<published>>')
          : governanceState.selectedGovernanceRecord.name
              .concat(' version ')
              .concat(governanceState.selectedGovernanceRecord.version)
              .concat(' format ')
              .concat(governanceState.selectedGovernanceRecord.format),
        value: governanceState.selectedGovernanceRecord.id,
      })
    }
  }, [governanceState.selectedGovernanceRecord, selectionEvent])

  const OptionSelect = () => {
    return (
      <Select
        id="input-governance-dropdown"
        name="governance_paths"
        placeholder="Select governance..."
        defaultValue={dbGovernance}
        options={governanceOptions}
        onChange={(e) => selectGovernance(e.value)}
        menuPortalTarget={document.body}
      />
    )
  }

  function selectGovernance(value) {
    // (eldersonar) Update reset the value of the governance selection HTML element
    if (selectedFileHTML) {
      setSelectedFileHTML((prev) => {
        const updatedState = prev
        updatedState.value = null
        return updatedState
      })
    }

    setSelectionEvent(true)
    props.sendRequest('GOVERNANCE', 'GET_GOVERNANCE_EDITOR_FILE_BY_ID', {
      id: value,
    })
  }

  const handleSubmit = (e) => {
    console.log('on handlesubmit')
    e.preventDefault()
    e.target.reset()
  }

  const addGovernance = (e) => {
    e.preventDefault()
    // (eldersonar) Clear selection for governance file (from DB)
    setDbGovernance('')
    const form = new FormData(governanceForm.current)
    const goverancePath = form.get('governance_path')

    //(RomanStepanyan) Checking if a URL starts with http or https
    const urlMatch =
      goverancePath.match('http://') || goverancePath.match('https://')
    if (urlMatch && urlMatch.index === 0) {
      props.sendRequest('GOVERNANCE', 'GET_GOVERNANCE_VIA_API', goverancePath)
    } else {
      return dispatch(
        setNotificationState({
          message:
            "URL address is not correct and governance file can't be selected",
          type: 'error',
        })
      )
    }
    governanceForm.current.reset()
  }

  const saveFile = () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()
    if (!file) {
      dispatch(
        setNotificationState({
          message: 'No governance file is selected to save',
          type: 'error',
        })
      )
    } else {
      props.sendRequest('GOVERNANCE', 'SAVE_GOVERNANCE_EDITOR_FILE', {
        id: file.id,
        file,
      })
    }
  }

  const publishLocally = () => {
    // (eldersonar) Fetch extracted governance file
    const file = extractGovernance()
    if (!file) {
      dispatch(
        setNotificationState({
          message: 'No governance file is selected to save',
          type: 'error',
        })
      )
    } else {
      props.sendRequest('GOVERNANCE', 'PUBLISH_GOVERNANCE_EDITOR_FILE', {
        // id: file.id,
        file,
      })
    }
  }

  // (eldersonar) This allows the component to render as many publish options as supported by the editor
  const renderExportButton = () => {
    if (publishOptions.length === 1) {
      return <ExportBtn onClick={exportGovernanceFile}>Publish</ExportBtn>
    } else if (publishOptions.length > 1) {
      return (
        <ExportBtn onClick={() => openGovernanceExport()}>Publish</ExportBtn>
      )
    }
    return null
  }

  return (
    <>
      <PageHeader title="Ecosystem Governance" />
      <PageSection>
        <GovernanceHeader>Governance Metadata</GovernanceHeader>
        <EditBtn
          id="button-edit"
          onClick={() =>
            governanceState.metadata &&
            Object.keys(governanceState.metadata).length !== 0 &&
            Object.getPrototypeOf(governanceState.metadata) === Object.prototype
              ? editMetadata()
              : dispatch(
                  setNotificationState({
                    message:
                      "Can't edit metadata before selecting governance file",
                    type: 'error',
                  })
                )
          }
        >
          Edit
        </EditBtn>
        <EditBtn id="button-create" onClick={() => addMetadata()}>
          Create
        </EditBtn>
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Author:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.author || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Name:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.name || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Description:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.description || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Version:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.version || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Format:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.format || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>URI:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.uri || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Human Readable Version URI:</th>
              <td>
                {governanceState.metadata !== undefined
                  ? governanceState.metadata.docs_uri || ''
                  : ''}
              </td>
            </AttributeRow>
            <AttributeRow>
              <th>Last Updated:</th>
              <td>
                {governanceState.metadata &&
                Object.keys(governanceState.metadata).length !== 0 &&
                Object.getPrototypeOf(governanceState.metadata) ===
                  Object.prototype
                  ? new Date(governanceState.metadata.last_updated * 1000)
                      .toISOString()
                      .slice(0, 19)
                      .replace('T', ' ') || ''
                  : ''}
              </td>
            </AttributeRow>
          </tbody>
        </AttributeTable>
        <H3>Get governance from files</H3>
        <Form onSubmit={handleGovernanceUpload}>
          <Input
            id="input-choose-file"
            type="file"
            accept=".json"
            onChange={governanceSelectHandler}
          ></Input>
          <SubmitFormBtn id="button-upload-governance-from-files" type="submit">
            Upload
          </SubmitFormBtn>
        </Form>
        <hr></hr>
        <Form onSubmit={handleSubmit} ref={governanceForm}>
          <H3>Get governance via API</H3>
          <Input
            id="input-governance-via-api"
            type="url"
            name="governance_path"
            ref={governancePath}
            placeholder="https://mrg.com/governance.json"
            required
          />
          <SubmitFormBtn
            id="button-upload-governance-from-api"
            type="submit"
            onClick={addGovernance}
          >
            Upload
          </SubmitFormBtn>
        </Form>
        <hr></hr>
        <H3>Get governance from the database</H3>
        <OptionSelect />
        <ExportBtn onClick={() => saveFile()}>Save to database</ExportBtn>
        <ExportBtn onClick={() => downloadFile()}>Download to files</ExportBtn>
        {renderExportButton()}
      </PageSection>
      <GovernanceMetadataAdd
        addMetadataModalIsOpen={addMetadataModalIsOpen}
        closeAddMetadataModal={closeAddMetadataModal}
      />
      <GovernanceMetadataEdit
        editMetadataModalIsOpen={editMetadataModalIsOpen}
        closeEditMetadataModal={closeEditMetadataModal}
      />
      <GovernanceExportModal
        governanceExportModalIsOpen={governanceExportModalIsOpen}
        closeGovernanceExportModal={closeGovernanceExportModal}
        publishOptions={publishOptions}
        exportGovernanceFile={exportGovernanceFile}
        exportGovernanceFileES3={exportGovernanceFileES3}
      />
    </>
  )
}

export default Governance

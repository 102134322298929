import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'
import ScheduledMaintenenceDateSelector from './components/ScheduledMaintenenceDateSelector'

function SettingsMaintananceForm(props) {
  const theme = useTheme()
  const { SettingsHeader, saveMaintenenceWindow } = props
  return (
    <>
      <SettingsHeader>Scheduled Maintenence</SettingsHeader>
      <IconHelp
        data-tip
        data-for="scheduledMaintenenceNotification"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="scheduledMaintenenceNotification"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          Future maintenence? Update date and time to cause a
          <br />
          notification to be shown on all admin routes.
        </span>
      </ReactTooltip>
      <ScheduledMaintenenceDateSelector
        saveMaintenenceWindow={saveMaintenenceWindow}
      />
    </>
  )
}

export default SettingsMaintananceForm

import { useRef } from 'react'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { H3 } from './CommonStyles'
import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'
import { IconHelp } from './CommonStylesTables'
import ReactTooltip from 'react-tooltip'
const PrimaryColorTest = styled.input`
  background: ${(props) => props.theme.primary_color};
  border: none;
  width: 13px;
`
const SecondaryColorTest = styled.input`
  background: ${(props) => props.theme.secondary_color};
  border: none;
  width: 13px;
`
const TertiaryColorTest = styled.input`
  background: ${(props) => props.theme.tertiary_color};
  border: none;
  width: 13px;
`
const NeutralColorTest = styled.input`
  background: ${(props) => props.theme.neutral_color};
  border: none;
  width: 13px;
`
const NegativeColorTest = styled.input`
  background: ${(props) => props.theme.negative_color};
  border: none;
  width: 13px;
`
const WarningColorTest = styled.input`
  background: ${(props) => props.theme.warning_color};
  border: none;
  width: 13px;
`
const PositiveColorTest = styled.input`
  background: ${(props) => props.theme.positive_color};
  border: none;
  width: 13px;
`
const TextColorTest = styled.input`
  background: ${(props) => props.theme.text_color};
  border: none;
  width: 13px;
`
const TextLightTest = styled.input`
  background: ${(props) => props.theme.text_light};
  border: none;
  width: 13px;
`
const BorderTest = styled.input`
  box-sizing: border-box;
  background: grey;
  height: 17px;
  width: 17px;
  border: ${(props) => props.theme.border};
`
const DropShadowTest = styled.input`
  background: grey;
  border: none;
  width: 13px;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const PrimaryBackgroundTest = styled.input`
  background: ${(props) => props.theme.background_primary};
  border: none;
  width: 13px;
`
const SecondaryBackgroundTest = styled.input`
  background: ${(props) => props.theme.background_secondary};
  border: none;
  width: 13px;
`
const UndoStyle = styled.button`
  width: 60px;
  background: ${(props) => props.theme.warning_color};
  padding: 5px;
  color: ${(props) => props.theme.text_light};
  border: none;
  margin-left: 20px;
  box-shadow: ${(props) => props.theme.drop_shadow};
  display: none;
  &.active {
    display: inline-block;
  }
`
const SaveBtn = styled.button`
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`
const SubmitFormBtn = styled.button``
const Input = styled.input``
const SettingsHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`
const StyledDataCell = styled(DataCell)`
  padding: 8px 25px;
`
const StyledDataHeader = styled(DataHeader)`
  padding: 8px 25px;
`

function ThemeSettings(props) {
  const {
    handleSubmit,
    undoStyle,
    saveStyle,
    stylesArray,
    addStylesToArray,
    updateTheme,
    setNotificationState,
  } = props
  const dispatch = useDispatch()
  const theme = useTheme()
  const primaryColorInput = useRef(null)
  const secondaryColorInput = useRef(null)
  const tertiaryColorInput = useRef(null)
  const neutralColorInput = useRef(null)
  const negativeColorInput = useRef(null)
  const warningColorInput = useRef(null)
  const positiveColorInput = useRef(null)
  const textColorInput = useRef(null)
  const textLightInput = useRef(null)
  const borderInput = useRef(null)
  const dropShadowInput = useRef(null)
  const primaryBackgroundInput = useRef(null)
  const secondaryBackgroundInput = useRef(null)

  // Theme style attribute change
  const changeThemeStyles = (key, value) => {
    let propertyName
    if (key === 'drop_shadow') {
      propertyName = 'box-shadow'
    } else if (key === 'border') {
      propertyName = 'border'
    } else {
      propertyName = 'color'
    }

    if (CSS.supports(propertyName, value)) {
      updateTheme({ [`${key}`]: value })
      addStylesToArray(key)
    } else {
      dispatch(
        setNotificationState({ message: 'Invalid color!', type: 'error' })
      )
    }
  }

  return (
    <>
      <SettingsHeader>Theme Settings</SettingsHeader>
      <IconHelp
        data-tip
        data-for="themeTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="themeTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          Use these settings to
          <br />
          update the UI appearance
        </span>
      </ReactTooltip>
      <DataTable>
        <thead>
          <DataRow>
            <StyledDataHeader>Theme Options</StyledDataHeader>
            <StyledDataHeader>Preview</StyledDataHeader>
          </DataRow>
        </thead>
        <tbody>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Primary Color</H3>
                <Input
                  defaultValue={theme.primary_color}
                  placeholder="Hex or string"
                  ref={primaryColorInput}
                  id="input-theme-primary-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'primary_color',
                      primaryColorInput.current.value
                    )
                  }
                  id="button-theme-primary-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <PrimaryColorTest disabled></PrimaryColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('primary_color') ? 'active' : ''
                }
                onClick={() => undoStyle('primary_color')}
                id="button-theme-primary-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Secondary Color</H3>
                <Input
                  defaultValue={theme.secondary_color}
                  placeholder="Hex or string"
                  ref={secondaryColorInput}
                  id="input-theme-secondary-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'secondary_color',
                      secondaryColorInput.current.value
                    )
                  }
                  id="button-theme-secondary-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <SecondaryColorTest disabled></SecondaryColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('secondary_color') ? 'active' : ''
                }
                onClick={() => undoStyle('secondary_color')}
                id="button-theme-secondary-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Tertiary Color</H3>
                <Input
                  defaultValue={theme.tertiary_color}
                  placeholder="Hex or string"
                  ref={tertiaryColorInput}
                  id="input-theme-tertiary-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'tertiary_color',
                      tertiaryColorInput.current.value
                    )
                  }
                  id="button-theme-tertiary-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <TertiaryColorTest disabled></TertiaryColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('tertiary_color') ? 'active' : ''
                }
                onClick={() => undoStyle('tertiary_color')}
                id="button-theme-tertiary-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Neutral Color</H3>
                <Input
                  defaultValue={theme.neutral_color}
                  placeholder="Hex or string"
                  ref={neutralColorInput}
                  id="input-theme-neutral-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'neutral_color',
                      neutralColorInput.current.value
                    )
                  }
                  id="button-theme-neutral-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <NeutralColorTest disabled></NeutralColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('neutral_color') ? 'active' : ''
                }
                onClick={() => undoStyle('neutral_color')}
                id="button-theme-neutral-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Negative Color</H3>
                <Input
                  defaultValue={theme.negative_color}
                  placeholder="Hex or string"
                  ref={negativeColorInput}
                  id="input-theme-negative-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'negative_color',
                      negativeColorInput.current.value
                    )
                  }
                  id="button-theme-negative-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <NegativeColorTest disabled></NegativeColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('negative_color') ? 'active' : ''
                }
                onClick={() => undoStyle('negative_color')}
                id="button-theme-negative-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Warning Color</H3>
                <Input
                  defaultValue={theme.warning_color}
                  placeholder="Hex or string"
                  ref={warningColorInput}
                  id="input-theme-warning-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'warning_color',
                      warningColorInput.current.value
                    )
                  }
                  id="button-theme-warning-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <WarningColorTest disabled></WarningColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('warning_color') ? 'active' : ''
                }
                onClick={() => undoStyle('warning_color')}
                id="button-theme-warning-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Positive Color</H3>
                <Input
                  defaultValue={theme.positive_color}
                  placeholder="Hex or string"
                  ref={positiveColorInput}
                  id="input-theme-positive-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'positive_color',
                      positiveColorInput.current.value
                    )
                  }
                  id="button-theme-positive-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <PositiveColorTest disabled></PositiveColorTest>
              <UndoStyle
                className={
                  stylesArray.includes('positive_color') ? 'active' : ''
                }
                onClick={() => undoStyle('positive_color')}
                id="button-theme-positive-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Text Color</H3>
                <Input
                  defaultValue={theme.text_color}
                  placeholder="Hex or string"
                  ref={textColorInput}
                  id="input-theme-text-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'text_color',
                      textColorInput.current.value
                    )
                  }
                  id="button-theme-text-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <TextColorTest disabled></TextColorTest>
              <UndoStyle
                className={stylesArray.includes('text_color') ? 'active' : ''}
                onClick={() => undoStyle('text_color')}
                id="button-theme-text-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Border</H3>
                <Input
                  defaultValue={theme.border}
                  placeholder="5px solid #ff0000 or string"
                  ref={borderInput}
                  id="input-theme-border"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles('border', borderInput.current.value)
                  }
                  id="button-theme-border-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <BorderTest disabled></BorderTest>
              <UndoStyle
                className={stylesArray.includes('border') ? 'active' : ''}
                onClick={() => undoStyle('border')}
                id="button-theme-border-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Text Light</H3>
                <Input
                  defaultValue={theme.text_light}
                  placeholder="Hex or string"
                  ref={textLightInput}
                  id="input-theme-text-light-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'text_light',
                      textLightInput.current.value
                    )
                  }
                  id="button-theme-text-light-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <TextLightTest disabled></TextLightTest>
              <UndoStyle
                className={stylesArray.includes('text_light') ? 'active' : ''}
                onClick={() => undoStyle('text_light')}
                id="button-theme-text-light-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Drop Shadow</H3>
                <Input
                  defaultValue={theme.drop_shadow}
                  placeholder="3px 3px 3px rgba(0, 0, 0, 0.3)"
                  ref={dropShadowInput}
                  id="input-theme-drop-shadow"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'drop_shadow',
                      dropShadowInput.current.value
                    )
                  }
                  id="button-theme-drop-shadow-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <DropShadowTest disabled></DropShadowTest>
              <UndoStyle
                className={stylesArray.includes('drop_shadow') ? 'active' : ''}
                onClick={() => undoStyle('drop_shadow')}
                id="button-theme-drop-shadow-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Primary Background</H3>
                <Input
                  defaultValue={theme.background_primary}
                  placeholder="Hex or string"
                  ref={primaryBackgroundInput}
                  id="input-theme-primary-background-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'background_primary',
                      primaryBackgroundInput.current.value
                    )
                  }
                  id="button-theme-primary-background-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <PrimaryBackgroundTest disabled></PrimaryBackgroundTest>
              <UndoStyle
                className={
                  stylesArray.includes('background_primary') ? 'active' : ''
                }
                onClick={() => undoStyle('background_primary')}
                id="button-theme-primary-background-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
          <DataRow>
            <StyledDataCell>
              <Form onSubmit={handleSubmit}>
                <H3>Change Secondary Background</H3>
                <Input
                  defaultValue={theme.background_secondary}
                  placeholder="Hex or string"
                  ref={secondaryBackgroundInput}
                  id="input-theme-secondary-background-color"
                />
                <SubmitFormBtn
                  type="submit"
                  onClick={() =>
                    changeThemeStyles(
                      'background_secondary',
                      secondaryBackgroundInput.current.value
                    )
                  }
                  id="button-theme-secondary-background-color-change"
                >
                  Change
                </SubmitFormBtn>
              </Form>
            </StyledDataCell>
            <StyledDataCell>
              <SecondaryBackgroundTest disabled></SecondaryBackgroundTest>
              <UndoStyle
                className={
                  stylesArray.includes('background_secondary') ? 'active' : ''
                }
                onClick={() => undoStyle('background_secondary')}
                id="button-theme-secondary-background-color-undo"
              >
                Undo
              </UndoStyle>
            </StyledDataCell>
          </DataRow>
        </tbody>
      </DataTable>
      <SaveBtn onClick={saveStyle} id="button-theme-save-all">
        Save Theme
      </SaveBtn>
    </>
  )
}

export default ThemeSettings

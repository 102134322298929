import { useState } from 'react'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'
import FormCredentialDelete from './FormCredentialDelete'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

import styled from 'styled-components'

const SaveBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
`

function Credential(props) {
  const credentials = useSelector(
    (state) => state.heldCredentials.heldCredentials
  )
  const credExId = props.credExId
  const [
    deleteCredentialModalIsOpen,
    setDeleteCredentialModalIsOpen,
  ] = useState(false)

  const closeDeleteCredentialModal = () => setDeleteCredentialModalIsOpen(false)

  const deleteFromWallet = () => {
    setDeleteCredentialModalIsOpen(true)
  }

  let credentialSelected = ''
  let attributesArray = ''

  for (let i = 0; i < credentials.length; i++) {
    if (credentials[i].credential_exchange_id === credExId) {
      credentialSelected = credentials[i]
      attributesArray = credentialSelected.values
      break
    }
  }

  // Initialize variables to blank (to prevent errors during loading)
  let showCredential = {
    name: '',
    connection_id: '',
    credential_id: '',
    credential_exchange_id: '',
    state: '',
    date_created: '',
    revoked: '',
  }

  // Attributes of this kind of credential
  let mappedAttributes = []

  // Now set the values if we have a credential
  if (credentialSelected !== '') {
    showCredential.name = credentialSelected.schema_id
      .split(':')[2]
      .replaceAll('_', ' ')
    showCredential.connection_id = credentialSelected.connection_id || ''
    showCredential.credential_id = credentialSelected.credential_id || ''
    showCredential.credential_exchange_id =
      credentialSelected.credential_exchange_id || ''
    showCredential.state = credentialSelected.state.replaceAll('_', ' ')
    showCredential.created_at =
      new Date(credentialSelected.created_at).toLocaleString() || ''
    showCredential.revoked =
      credentialSelected.revoked === true ? 'true' : 'false'

    // Values that depend on the credential being issued
    if (attributesArray) {
      mappedAttributes = attributesArray.map((attribute, index) => {
        return (
          <AttributeRow key={attribute.name}>
            <th>
              {attribute.name
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </th>
            <td>{attribute.value}</td>
          </AttributeRow>
        )
      })
    }
  }

  return (
    <div id="contact">
      <PageHeader title={showCredential.name} />
      <PageSection>
        <h2>General Information</h2>
        <SaveBtn
          title="Delete credential from waller"
          onClick={deleteFromWallet}
        >
          Delete
        </SaveBtn>
        <AttributeTable>
          <tbody>
            <AttributeRow>
              <th>Credential Name:</th>
              <td>{showCredential.name}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Credential ID:</th>
              <td>{showCredential.credential_id}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Credential Exchange ID:</th>
              <td>{showCredential.credential_exchange_id}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Credential State:</th>
              <td>{showCredential.state}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Date Created:</th>
              <td>{showCredential.created_at}</td>
            </AttributeRow>
            <AttributeRow>
              <th>Revoked:</th>
              <td>{showCredential.revoked}</td>
            </AttributeRow>
            <FormCredentialDelete
              sendRequest={props.sendRequest}
              deleteCredentialModalIsOpen={deleteCredentialModalIsOpen}
              closeDeleteCredentialModal={closeDeleteCredentialModal}
              credential_id={showCredential.credential_id}
            />
          </tbody>
        </AttributeTable>
        <h2>Attributes</h2>
        <AttributeTable>
          <tbody>{mappedAttributes}</tbody>
        </AttributeTable>
      </PageSection>
    </div>
  )
}

export default Credential

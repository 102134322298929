import { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { H3 } from './CommonStyles'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsManifestForm(props) {
  const theme = useTheme()
  const manifestShortName = useRef(null)
  const manifestName = useRef(null)
  const manifestThemeColor = useRef(null)
  const manifestBackgroundColor = useRef(null)

  const {
    SettingsHeader,
    Form,
    BlockInput,
    SaveBtn,
    handleSubmit,
    settingsState,
    manifestDetailsForm,
    handleManifest,
  } = props
  return (
    <>
      <SettingsHeader>Web App Manifest</SettingsHeader>
      <IconHelp
        data-tip
        data-for="manifestTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="manifestTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          The web app manifest provides information about
          <br />
          a web application in a JSON text file to provide
          <br />
          users with quicker access and a richer experience.
        </span>
      </ReactTooltip>
      <Form onSubmit={handleSubmit} ref={manifestDetailsForm}>
        <H3>Short Name</H3>
        <BlockInput
          type="text"
          name="short_manifest_name"
          defaultValue={
            settingsState.manifest?.short_name ||
            settingsState.defaultSettings.manifest?.short_name ||
            ''
          }
          ref={manifestShortName}
          id="input-manifest-short-name"
        />
        <H3>Full Name</H3>
        <BlockInput
          type="text"
          name="manifest_name"
          defaultValue={
            settingsState.manifest?.name ||
            settingsState.defaultSettings.manifest?.name ||
            ''
          }
          ref={manifestName}
          id="input-manifest-full-name"
        />
        <H3>Theme Color</H3>
        <BlockInput
          type="text"
          name="theme_color"
          defaultValue={
            settingsState.manifest?.theme_color ||
            settingsState.defaultSettings.manifest?.theme_color ||
            ''
          }
          ref={manifestThemeColor}
          id="input-manifest-theme-color"
        />
        <H3>Background Color</H3>
        <BlockInput
          type="text"
          name="background_color"
          defaultValue={
            settingsState.manifest?.background_color ||
            settingsState.defaultSettings.manifest?.background_color ||
            ''
          }
          ref={manifestBackgroundColor}
          id="input-manifest-background-color"
        />
        <SaveBtn onClick={handleManifest} id="button-manifest-save">
          Save
        </SaveBtn>
      </Form>
    </>
  )
}

export default SettingsManifestForm

import { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { H3 } from './CommonStyles'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsOrganizationDetailsForm(props) {
  const theme = useTheme()
  const {
    SettingsHeader,
    Form,
    BlockInput,
    SaveBtn,
    handleSubmit,
    settingsState,
    organizationForm,
    handleOrganizationDetails,
  } = props

  const organizationName = useRef(null)
  const siteTitle = useRef(null)

  return (
    <>
      <SettingsHeader>Organization Details</SettingsHeader>
      <IconHelp
        data-tip
        data-for="organizationTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="organizationTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          Organization name is used in
          <br />
          the UI and email messages.
          <br />
          <br />
          A website title identifies what
          <br />
          the web page is about for both
          <br />
          web users and search engines.
        </span>
      </ReactTooltip>
      <Form onSubmit={handleSubmit} ref={organizationForm}>
        <H3>Organization Name</H3>
        <BlockInput
          name="organizationName"
          defaultValue={
            settingsState.defaultSettings?.organization?.organizationName ||
            settingsState.organizationName ||
            ''
          }
          ref={organizationName}
          id="input-website-title"
        />
        <H3>Website Title</H3>
        <BlockInput
          name="siteTitle"
          defaultValue={
            settingsState.defaultSettings?.organization?.title ||
            settingsState.siteTitle ||
            ''
          }
          ref={siteTitle}
        />
        <SaveBtn onClick={handleOrganizationDetails} id="button-save">
          Save
        </SaveBtn>
      </Form>
    </>
  )
}

export default SettingsOrganizationDetailsForm

const initialState = {
  subwallets: [],
  scopedWallets: [],
  apiKey: {},
  walletNeedsSettings: true,
}

const SET_SUBWALLETS = 'SET_SUBWALLETS'
const SET_SCOPEED_WALLETS = 'SET_SCOPEED_WALLETS'
const SET_API_KEY = 'SET_API_KEY'
const CLEAR_MULTITENANCY_STATE = 'CLEAR_MULTITENANCY_STATE'
const WALLET_SETTINGS_NEED_CREATION = 'WALLET_SETTINGS_NEED_CREATION'

export function setSubwallets(subwallets) {
  return {
    type: SET_SUBWALLETS,
    payload: subwallets,
  }
}

export function setScopedWallets(scopedWallets) {
  return {
    type: SET_SCOPEED_WALLETS,
    payload: scopedWallets,
  }
}

export function setApiKey(apiKey) {
  return {
    type: SET_API_KEY,
    payload: apiKey,
  }
}

export function clearMultitenancyState() {
  return {
    type: CLEAR_MULTITENANCY_STATE,
    payload: null,
  }
}

export function setWalletSettingsNeedCreation(walletNeedsSettings) {
  return {
    type: WALLET_SETTINGS_NEED_CREATION,
    payload: walletNeedsSettings,
  }
}

export default function multitenancyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SUBWALLETS:
      return { ...state, subwallets: action.payload }

    case SET_SCOPEED_WALLETS:
      return { ...state, scopedWallets: action.payload }

    case SET_API_KEY:
      return { ...state, apiKey: action.payload }

    case CLEAR_MULTITENANCY_STATE:
      return { subwallets: [], scopedWallets: [], apiKey: {} }

    case WALLET_SETTINGS_NEED_CREATION:
      return { ...state, walletNeedsSettings: action.payload }

    default:
      return state
  }
}

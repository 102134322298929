import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsLogo192Form(props) {
  const theme = useTheme()
  const {
    SettingsHeader,
    Form,
    PreviewWrapper,
    PreviewContainer,
    Input,
    UploadImageBtn,
    DefaultImageSubmitBtn,
    ImageWrapper,
    PreviewImage,
    logo192FileName,
    uploaded,
    handleLogo192Submit,
    handleDefaultLogo192Select,
    logo192SelectHandler,
    selectedLogo192,
  } = props
  return (
    <>
      <SettingsHeader>Change Logo 192x192</SettingsHeader>
      <IconHelp
        data-tip
        data-for="logo192Tip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="logo192Tip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          logo192.png is the icon used to show on the tab
          <br />
          of mobile device. logo192.png is part of PWA.
          <br />
          The max size is 100KB.
        </span>
      </ReactTooltip>
      <PreviewWrapper>
        <PreviewContainer>
          <Form onSubmit={handleLogo192Submit}>
            <Input
              type="file"
              accept=".png"
              onChange={logo192SelectHandler}
              id="input-192x192-change-logo-choose-file"
            ></Input>
            <UploadImageBtn
              type="submit"
              id="button-192x192-change-logo-upload"
              disabled={!selectedLogo192}
            >
              Upload
            </UploadImageBtn>
          </Form>
          <Form onSubmit={handleDefaultLogo192Select}>
            <DefaultImageSubmitBtn
              type="submit"
              id="button-update-default-logo192-upload"
            >
              Select Default Logo192
            </DefaultImageSubmitBtn>
          </Form>
        </PreviewContainer>
        {selectedLogo192 && logo192FileName && !uploaded && (
          <ImageWrapper>
            <PreviewImage src={selectedLogo192} alt="Selected Logo" />
          </ImageWrapper>
        )}
      </PreviewWrapper>
    </>
  )
}

export default SettingsLogo192Form

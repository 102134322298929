import { useRef, useEffect } from 'react'

import {
  Actions,
  CancelBtn,
  CloseBtn,
  Modal,
  ModalContentWrapper,
  ModalContent,
  ModalHeader,
  StyledPopup,
  SubmitBtnModal,
} from './CommonStylesForms'

function FormCredentiaDeletion(props) {
  const error = props.error

  const deleteCredentialForm = useRef()
  const submitBtn = useRef()

  useEffect(() => {
    if (error && submitBtn.current) {
      submitBtn.current.removeAttribute('disabled')
    }
  }, [error])

  // Disable button on submit
  const onBtnClick = (e) => {
    if (submitBtn.current) {
      submitBtn.current.setAttribute('disabled', 'disabled')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    onBtnClick()

    props.sendRequest('HELD_CREDENTIALS', 'DELETE', props.credential_id)
    closeModal()
  }

  function closeModal() {
    props.closeDeleteCredentialModal()
  }

  return (
    <StyledPopup
      open={props.deleteCredentialModalIsOpen}
      closeOnDocumentClick
      onClose={closeModal}
    >
      <Modal className="modal">
        <ModalHeader>
          Are you sure you want to delete credential from a storage?
        </ModalHeader>
        <ModalContentWrapper>
          <ModalContent>
            <form id="form" onSubmit={handleSubmit} ref={deleteCredentialForm}>
              <Actions>
                <CancelBtn type="button" onClick={closeModal}>
                  Cancel
                </CancelBtn>
                <SubmitBtnModal type="submit" ref={submitBtn}>
                  Submit
                </SubmitBtnModal>
              </Actions>
            </form>
          </ModalContent>
        </ModalContentWrapper>
        <CloseBtn onClick={closeModal}>&times;</CloseBtn>
      </Modal>
    </StyledPopup>
  )
}

export default FormCredentiaDeletion

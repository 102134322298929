import { useState } from 'react'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader'
import PageSection from './PageSection'

import { DataTable, DataRow, DataHeader, DataCell } from './CommonStylesTables'
import { Button } from './CommonStylesForms'
import styled from 'styled-components'

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StatusLabelText = styled.p`
  margin-bottom: 0.2px;
`
const ButtonHolder = styled.div``

function Wallet(props) {
  const credentials = useSelector(
    (state) => state.heldCredentials.heldCredentials
  )
  const presentations = useSelector((state) => state.proofs.proofReports)
  const contacts = useSelector((state) => state.contacts.contacts)

  const history = props.history

  const [disabledButtons, setDisabledButtons] = useState({})

  const handleDisableButton = (id, action) => {
    setDisabledButtons((prev) => ({
      ...prev,
      [id]: { ...prev[id], [action]: true },
    }))
  }

  const credentialRows = credentials.map((credential_record) => {
    const acceptedCred =
      credential_record.state === 'done' ||
      credential_record.state === 'credential_acked'
    const rejectedCred = credential_record.state === 'abandoned'

    const credExId = credential_record.credential_exchange_id || ''
    const credentialState = credential_record.state?.replaceAll('_', ' ') || ''
    const dateCreated =
      new Date(credential_record.created_at).toLocaleString() || ''
    const revoked = credential_record.revoked === true ? 'true' : 'false'
    const credentialName = credential_record.schema_id
      .split(':')[2]
      .replaceAll('_', ' ')

    return (
      <DataRow key={credExId}>
        <DataCell
          onClick={() => {
            openCredential(history, credExId)
          }}
        >
          {credentialName}
        </DataCell>
        <DataCell
          className="title-case"
          onClick={() => {
            openCredential(history, credExId)
          }}
        >
          {credentialState}
        </DataCell>
        <DataCell
          onClick={() => {
            openCredential(history, credExId)
          }}
        >
          {revoked}
        </DataCell>
        <DataCell
          onClick={() => {
            openCredential(history, credExId)
          }}
        >
          {dateCreated}
        </DataCell>
        <DataCell>
          {!acceptedCred && !rejectedCred ? (
            <FlexWrapper>
              <ButtonHolder>
                <Button
                  onClick={(e) => {
                    acceptCredentialOffer(e, credExId)
                    handleDisableButton(credExId, 'accept')
                  }}
                  disabled={disabledButtons[credExId]?.accept}
                >
                  Accept
                </Button>
              </ButtonHolder>
              <ButtonHolder>
                <Button
                  onClick={(e) => {
                    rejectCredentialOffer(e, credExId)
                    handleDisableButton(credExId, 'decline')
                  }}
                  disabled={disabledButtons[credExId]?.decline}
                >
                  Decline
                </Button>
              </ButtonHolder>
            </FlexWrapper>
          ) : (
            <>
              {acceptedCred && <StatusLabelText>Accepted</StatusLabelText>}
              {rejectedCred && <StatusLabelText>Declined</StatusLabelText>}
            </>
          )}
        </DataCell>
      </DataRow>
    )
  })

  for (let i = 0; i < presentations.length; i++) {
    for (let j = 0; j < contacts.length; j++) {
      if (
        presentations[i].connection_id ===
        contacts[j].Connections[0].connection_id
      ) {
        presentations[i].contact_label = contacts[j].label
        break
      }
    }
  }

  const openProof = (history, id) => {
    if (history !== undefined) {
      history.push('/admin/wallet/credentials-shared/' + id)
    }
  }

  const openCredential = (history, id) => {
    if (history !== undefined) {
      history.push('/admin/wallet/credentials-received/' + id)
    }
  }

  const acceptCredentialOffer = async (e, credExId) => {
    e.preventDefault()
    props.sendRequest('HELD_CREDENTIALS', 'ACCEPT', {
      credExId,
    })
  }

  const rejectCredentialOffer = async (e, credExId) => {
    e.preventDefault()
    props.sendRequest('HELD_CREDENTIALS', 'REJECT', {
      credExId,
    })
  }

  const acceptPresentationRequest = async (e, presExId) => {
    e.preventDefault()
    props.sendRequest('PROOFS', 'ACCEPT', {
      presExId,
    })
  }

  const rejectPresentationRequest = async (e, presExId) => {
    e.preventDefault()
    props.sendRequest('PROOFS', 'REJECT', {
      presExId,
    })
  }

  const proofRows = presentations.map((presentation_record) => {
    const acceptedProof =
      presentation_record.state === 'done' ||
      presentation_record.state === 'presentation_acked'
    const rejectedProof = presentation_record.state === 'abandoned'
    const deletedProof = presentation_record.state === 'deleted'

    const presExId = presentation_record.presentation_exchange_id
    const presentationState =
      presentation_record.state.replaceAll('_', ' ') || ''
    const dateCreated =
      new Date(presentation_record.created_at).toLocaleString() || ''
    const presentationRequest = presentation_record.presentation_request
    const nameSet = new Set()

    if (presentationRequest && presentationRequest.requested_attributes) {
      Object.keys(presentationRequest.requested_attributes).forEach(
        (referant) => {
          const restrictions =
            presentationRequest.requested_attributes[referant].restrictions
          if (restrictions && restrictions[0]) {
            if (restrictions[0].schema_id) {
              nameSet.add(
                restrictions[0].schema_id.split(':')[2].replaceAll('_', ' ')
              )
            } else {
              nameSet.add('any schema')
            }
          } else {
            nameSet.add('self-attested')
          }
        }
      )
    }
    const requestName = Array.from(nameSet).join(', ')

    return (
      <DataRow key={presExId}>
        <DataCell
          onClick={() => {
            openProof(history, presExId)
          }}
        >
          {presentation_record.contact_label}
        </DataCell>
        <DataCell
          onClick={() => {
            openProof(history, presExId)
          }}
        >
          {requestName}
        </DataCell>
        <DataCell
          className="title-case"
          onClick={() => {
            openProof(history, presExId)
          }}
        >
          {presentationState}
        </DataCell>
        <DataCell
          onClick={() => {
            openProof(history, presExId)
          }}
        >
          {dateCreated}
        </DataCell>
        <DataCell>
          {!acceptedProof && !rejectedProof && !deletedProof ? (
            <FlexWrapper>
              <ButtonHolder>
                <Button
                  onClick={(e) => {
                    acceptPresentationRequest(e, presExId)
                    handleDisableButton(presExId, 'accept')
                  }}
                  disabled={disabledButtons[presExId]?.accept}
                >
                  Accept
                </Button>
              </ButtonHolder>
              <ButtonHolder>
                <Button
                  onClick={(e) => {
                    rejectPresentationRequest(e, presExId)
                    handleDisableButton(presExId, 'decline')
                  }}
                  disabled={disabledButtons[presExId]?.decline}
                >
                  Decline
                </Button>
              </ButtonHolder>
            </FlexWrapper>
          ) : (
            <>
              {deletedProof && <StatusLabelText>Deleted</StatusLabelText>}
              {acceptedProof && <StatusLabelText>Accepted</StatusLabelText>}
              {rejectedProof && <StatusLabelText>Declined</StatusLabelText>}
            </>
          )}
        </DataCell>
      </DataRow>
    )
  })

  return (
    <>
      <div id="wallet">
        <PageHeader title={'Wallet'} />
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Credential Received</DataHeader>
                <DataHeader>State</DataHeader>
                <DataHeader>Revoked</DataHeader>
                <DataHeader>Date Received</DataHeader>
                <DataHeader>Status/Action</DataHeader>
              </DataRow>
            </thead>
            <tbody>{credentialRows}</tbody>
          </DataTable>
        </PageSection>
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Credential Shared</DataHeader>
                <DataHeader>Request Name</DataHeader>
                <DataHeader>State</DataHeader>
                <DataHeader>Date Issued</DataHeader>
                <DataHeader>Status/Action</DataHeader>
              </DataRow>
            </thead>
            <tbody>{proofRows}</tbody>
          </DataTable>
        </PageSection>
      </div>
    </>
  )
}

export default Wallet

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useRouteMatch } from 'react-router-dom'
import AppHeader from '../UI/AppHeader'
import { Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components'

import { check } from '../UI/CanUser'
import Contact from '../UI/Contact'
import Contacts from '../UI/Contacts'
import Credential from '../UI/Credential'
import Credentials from '../UI/Credentials'
import HeldCredential from '../UI/HeldCredential'
import Help from '../UI/Help'
import Home from '../UI/Home'
import Presentation from '../UI/Presentation'
import Presentations from '../UI/Presentations'
import Proof from '../UI/Proof'
import Wallet from '../UI/Wallet'
import MaintenenceNotification from '../UI/components/MaintenenceNotification.js'

// Core
import Governance from '../modules/GovernanceEditor/Core/Governance'
import GovernanceFiles from '../modules/GovernanceEditor/Core/Files'

// Format 1.0
import GovernanceParticipantV1 from '../modules/GovernanceEditor/Formats/Format1/Participant1'
import GovernanceParticipantsV1 from '../modules/GovernanceEditor/Formats/Format1/Participants1'
import GovernanceSchemaV1 from '../modules/GovernanceEditor/Formats/Format1/Schema1'
import GovernanceSchemasV1 from '../modules/GovernanceEditor/Formats/Format1/Schemas1'

// Format 2.0
import GovernanceSchemasV2 from '../modules/GovernanceEditor/Formats/Format2/Schemas2'
import GovernanceSchemaV2 from '../modules/GovernanceEditor/Formats/Format2/Schema2'
import GovernanceParticipantsV2 from '../modules/GovernanceEditor/Formats/Format2/Participants2'
import GovernanceParticipantV2 from '../modules/GovernanceEditor/Formats/Format2/Participant2'

import Invitation from '../UI/Invitation'
import Invitations from '../UI/Invitations'
import Settings from '../UI/Settings'
import Schemas from '../UI/Schemas'
import SchemaIssuance from '../UI/SchemaIssuance'
import SchemaVerification from '../UI/SchemaVerification'
import UsersGuide from '../UI/UsersGuide.js'
import Multitenancy from '../UI/MultiTenancy.js'

import Endorsements from '../UI/Endorsements.js'

import { setUserRole } from '../redux/endorserReducer.js'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function AdminRoutes(props) {
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  const governanceState = useSelector((state) => state.governance)
  const invitationsState = useSelector((state) => state.invitations)
  const contactsState = useSelector((state) => state.contacts)
  const settingsState = useSelector((state) => state.settings)
  const heldCredentials = useSelector((state) => state.heldCredentials)
  const currentState = useSelector((state) => state)
  // (anwalker293) Will want to move endorserState to module eventually.
  // ^Conditional rendering of UI for modules not supported at this time.
  const endorserState = useSelector((state) => state.endorser)

  // (eldersonar) This will give the warning only if the admin decided to refresh the page
  // while making updates to the governance file (governance routes only).
  useEffect(() => {
    const myEvent = window.attachEvent || window.addEventListener
    const checkEvent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'

    const handleBeforeUnload = (e) => {
      const pathname = props.history.location.pathname
      const pattern = /^\/admin\/governance($|\/|\/*)/
      if (pattern.test(pathname)) {
        const confirmationMessage = 'Are you sure to leave the page?' // This custom message won't be displayed.
        ;(e || window.event).returnValue = confirmationMessage
        return confirmationMessage
      }
    }

    myEvent(checkEvent, handleBeforeUnload)

    return () => {
      window.removeEventListener(checkEvent, handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    // Handle endorser module on first time
    // (anwalker293) There's some redundant logic here
    // because role is not wanting to update below...
    if (
      !localStorage.getItem('agentConfig') ||
      !settingsState.agentConfig ||
      Object.keys(settingsState.agentConfig).length === 0
    ) {
      props.sendMessage('SETTINGS', 'GET_AGENT_CONFIG', {})
    }
  }, [])

  const handleEndorserModule = (config) => {
    let role
    if (config && config['endorser.endorser'] == true) {
      role = 'endorser'
    } else if (config && config['endorser.author'] == true) {
      role = 'author'
    } else {
      role = ''
    }
    dispatch(setUserRole(role))
    return role
  }

  useEffect(() => {
    const config =
      JSON.parse(localStorage.getItem('agentConfig')) ||
      settingsState.agentConfig
    const newRole = handleEndorserModule(config)
    dispatch(setUserRole(newRole))
  }, [settingsState.agentConfig])

  return (
    <>
      <Switch>
        <Route exact path={`${path}/login`}>
          <Redirect to="/admin" />
        </Route>
        <Route
          path="/admin"
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  sendRequest={props.sendMessage}
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  {settingsState.maintenenceWindow.active && (
                    <MaintenenceNotification />
                  )}
                  <Home
                    sendRequest={props.sendMessage}
                    verifiedCredential={props.verifiedCredential}
                    setVerifiedCredential={props.setVerifiedCredential}
                  />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          exact
          path={`${path}/invitations`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Invitations
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/invitations/:invitationId`}
          render={({ match, history }) => {
            if (check('invitations:read')) {
              if (
                invitationsState.invitations.rows?.length &&
                invitationsState.invitations.rows.some(
                  (invitation) =>
                    invitation.invitation_id ===
                    Number(match.params.invitationId)
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <Invitation
                        history={history}
                        sendRequest={props.sendMessage}
                        invitationId={match.params.invitationId}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return (
                  <Route render={() => <Redirect to="/admin/invitations" />} />
                )
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts`}
          exact
          render={({ match, history }) => {
            if (check('contacts:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Contacts
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/contacts/:contactId`}
          render={({ match, history }) => {
            if (check('contacts:read')) {
              if (
                contactsState.contacts.rows?.length &&
                contactsState.contacts.rows.some(
                  (contact) => contact.contact_id === match.params.contactId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <Contact
                        history={history}
                        sendRequest={props.sendMessage}
                        contactId={match.params.contactId}
                        verifiedCredential={props.verifiedCredential}
                        setVerifiedCredential={props.setVerifiedCredential}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return (
                  <Route render={() => <Redirect to="/admin/contacts" />} />
                )
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/schemas`}
          exact
          render={({ match, history }) => {
            if (check('schemas:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Schemas
                      history={history}
                      sendRequest={props.sendMessage}
                      userRole={endorserState.userRole}
                      authorRoleIsSet={props.authorRoleIsSet}
                      endorserInfoIsSet={props.endorserInfoIsSet}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/schemas/issuance/:schemaId`}
          render={({ match, history }) => {
            if (check('schemas:read')) {
              if (
                currentState.schemas.schemas.length &&
                currentState.schemas.schemas.some(
                  (schema) => schema.schema_id === match.params.schemaId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <SchemaIssuance
                        history={history}
                        sendRequest={props.sendMessage}
                        schemaId={match.params.schemaId}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return <Route render={() => <Redirect to="/admin/schemas" />} />
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />

        <Route
          path={`${path}/endorsements`}
          exact
          render={({ match, history }) => {
            if (endorserState.userRole === 'endorser') {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    <Endorsements
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/schemas/verification/:id`}
          render={({ match, history }) => {
            if (check('schemas:read')) {
              if (
                currentState.schemas.verificationDefinitions.length &&
                currentState.schemas.verificationDefinitions.some(
                  (schema) => schema.id === Number(match.params.id)
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <SchemaVerification
                        history={history}
                        sendRequest={props.sendMessage}
                        id={match.params.id}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return <Route render={() => <Redirect to="/admin/schemas" />} />
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/presentations`}
          exact
          render={({ match, history }) => {
            if (check('presentations:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Presentations history={history} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/presentations/:presentationId`}
          render={({ match, history }) => {
            if (check('presentations:read')) {
              if (
                currentState.presentations.presentationReports.length &&
                currentState.presentations.presentationReports.some(
                  (presentation) =>
                    presentation.presentation_exchange_id ===
                    match.params.presentationId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <Presentation
                        history={history}
                        presentation={match.params.presentationId}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return (
                  <Route
                    render={() => <Redirect to="/admin/presentations" />}
                  />
                )
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/credentials`}
          exact
          render={({ match, history }) => {
            if (check('credentials:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Credentials history={history} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/credentials/:credentialId`}
          render={({ match, history }) => {
            if (check('credentials:read')) {
              if (
                currentState.credentials.credentials.length &&
                currentState.credentials.credentials.some(
                  (credential) =>
                    credential.credential_exchange_id ===
                    match.params.credentialId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <Credential
                        history={history}
                        credential={match.params.credentialId}
                        sendRequest={props.sendMessage}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return (
                  <Route render={() => <Redirect to="/admin/credentials" />} />
                )
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/wallet`}
          exact
          render={({ match, history }) => {
            if (check('credentialsReceived:read, credentialsShared:read')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Wallet history={history} sendRequest={props.sendMessage} />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/wallet/credentials-received/:credExId`}
          render={({ match, history }) => {
            if (check('credentialsReceived:read')) {
              if (
                heldCredentials.heldCredentials.length &&
                heldCredentials.heldCredentials.some(
                  (credential) =>
                    credential.credential_exchange_id === match.params.credExId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <HeldCredential
                        history={history}
                        credExId={match.params.credExId}
                        sendRequest={props.sendMessage}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return <Route render={() => <Redirect to="/admin/wallet" />} />
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/wallet/credentials-shared/:presExId`}
          render={({ match, history }) => {
            if (check('credentialsShared:read')) {
              if (
                currentState.proofs.proofReports.length &&
                currentState.proofs.proofReports.some(
                  (proof) =>
                    proof.presentation_exchange_id === match.params.presExId
                )
              ) {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && (
                        <MaintenenceNotification />
                      )}
                      <Proof
                        history={history}
                        presExId={match.params.presExId}
                      />
                    </Main>
                  </Frame>
                )
              } else {
                return <Route render={() => <Redirect to="/admin/wallet" />} />
              }
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/multi-tenancy`}
          render={({ match, history }) => {
            if (check('multitenancy:createAPIKey')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Multitenancy
                      history={history}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        <Route
          path={`${path}/messages`}
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  sendRequest={props.sendMessage}
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  {settingsState.maintenenceWindow.active && (
                    <MaintenenceNotification />
                  )}
                  <p>Messages</p>
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/help`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  sendRequest={props.sendMessage}
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  {settingsState.maintenenceWindow.active && (
                    <MaintenenceNotification />
                  )}
                  <Help />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/help/users-guide`}
          exact
          render={({ match, history }) => {
            return (
              <Frame id="app-frame">
                <AppHeader
                  sendRequest={props.sendMessage}
                  match={match}
                  history={history}
                  handleLogout={props.handleLogout}
                />
                <Main>
                  {settingsState.maintenenceWindow.active && (
                    <MaintenenceNotification />
                  )}
                  <UsersGuide />
                </Main>
              </Frame>
            )
          }}
        />
        <Route
          path={`${path}/settings`}
          render={({ match, history }) => {
            if (check('settings:update')) {
              return (
                <Frame id="app-frame">
                  <AppHeader
                    sendRequest={props.sendMessage}
                    match={match}
                    history={history}
                    handleLogout={props.handleLogout}
                  />
                  <Main>
                    {settingsState.maintenenceWindow.active && (
                      <MaintenenceNotification />
                    )}
                    <Settings
                      needsSMTP={true}
                      updateTheme={props.updateTheme}
                      saveTheme={props.saveTheme}
                      saveMaintenenceWindow={props.saveMaintenenceWindow}
                      undoStyle={props.undoStyle}
                      stylesArray={props.stylesArray}
                      addStylesToArray={props.addStylesToArray}
                      clearStylesArray={props.clearStylesArray}
                      removeStylesFromArray={props.removeStylesFromArray}
                      sendRequest={props.sendMessage}
                    />
                  </Main>
                </Frame>
              )
            } else {
              return <Route render={() => <Redirect to="/" />} />
            }
          }}
        />
        {governanceState.metadata.format === '1.0' &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV1
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV1
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 2.0  or when governance file not selected*/}
        {(governanceState.metadata.format === '2.0' ||
          !governanceState.metadata.format) &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* <Route
              path={`${path}/governance/governance-editor`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && <MaintenenceNotification/>}
                      <Governance sendRequest={props.sendMessage} />
                    </Main>
                  </Frame>
                )
              }}
            /> */}
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV2
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV2
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 1.0 */}
        {governanceState.metadata.format !== '1.0' &&
          governanceState.metadata.format !== '2.0' &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* <Route
              path={`${path}/governance-editor`}
              exact
              render={({ match, history }) => {
                return (
                  <Frame id="app-frame">
                    <AppHeader
                      sendRequest={props.sendMessage}
                      match={match}
                      history={history}
                      handleLogout={props.handleLogout}
                    />
                    <Main>
                      {settingsState.maintenenceWindow.active && <MaintenenceNotification/>}
                      <Governance sendRequest={props.sendMessage} />
                    </Main>
                  </Frame>
                )
              }}
            /> */}
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV1
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV1
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          sendRequest={props.sendMessage}
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 2.0  or when governance file not selected*/}
        {(governanceState.metadata.format === '2.0' ||
          !governanceState.metadata.format) &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV2
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV2
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 1.0 */}
        {governanceState.metadata.format !== '1.0' &&
          governanceState.metadata.format !== '2.0' &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV1
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV1
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 2.0  or when governance file not selected*/}
        {(governanceState.metadata.format === '2.0' ||
          !governanceState.metadata.format) &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV2
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV2 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV2
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* (eldersonar) Render components for governance file format 1.0 */}
        {governanceState.metadata.format !== '1.0' &&
          governanceState.metadata.format !== '2.0' &&
          window.location.pathname.includes('governance') && (
            <>
              <Route
                path={`${path}/governance`}
                exact
                render={({ match, history }) => {
                  if (check('governanceFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceFiles
                            history={history}
                            sendRequest={props.sendMessage}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemasV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/schemas/:id`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceSchemaV1
                            history={history}
                            id={match.params.id}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantsV1 history={history} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor/participants/:participantId`}
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <GovernanceParticipantV1
                            history={history}
                            participantId={match.params.participantId}
                          />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              <Route
                path={`${path}/governance/governance-editor`}
                exact
                render={({ match, history }) => {
                  if (check('governanceEditorFiles:read')) {
                    return (
                      <Frame id="app-frame">
                        <AppHeader
                          match={match}
                          history={history}
                          handleLogout={props.handleLogout}
                        />
                        <Main>
                          {settingsState.maintenenceWindow.active && (
                            <MaintenenceNotification />
                          )}
                          <Governance sendRequest={props.sendMessage} />
                        </Main>
                      </Frame>
                    )
                  } else {
                    return <Route render={() => <Redirect to="/" />} />
                  }
                }}
              />
              {/* Redirect to root if no route match is found */}
              <Route
                render={() =>
                  check('governanceFiles:read') ? (
                    <Redirect to={`${path}/governance`} />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />
            </>
          )}
        {/* Redirect to root if no route match is found */}
        <Route render={() => <Redirect to="/admin" />} />
      </Switch>
    </>
  )
}

export default AdminRoutes

import React from 'react'
import styled from 'styled-components'
import poweredByImage from '../assets/powered-by.png'
import indicioImage from '../assets/indicio.png'
import {
  FormContainer,
  LogoHolder,
  Logo,
  SubmitBtnModal,
  PoweredBy,
} from '../UI/CommonStylesForms'

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
`
const StyledSubmitBtnModal = styled(SubmitBtnModal)`
  margin: 40px;
  width: 30%;
`
const StyledSpacedLogoHolder = styled(LogoHolder)`
  padding: 20px 0px 0px;
`
const StyledFormContainer = styled(FormContainer)`
  width: 85%;
  max-width: 500px;
  min-width: 300px;
`

const RootPage = () => {
  const navigateToLogin = () => {
    window.location.href = '/auth/login'
  }

  return (
    <CenteredContainer>
      <StyledFormContainer>
        <StyledSpacedLogoHolder>
          <Logo src={indicioImage} alt="Indicio Logo" />
          <StyledSubmitBtnModal onClick={navigateToLogin}>
            Go to Login
          </StyledSubmitBtnModal>
        </StyledSpacedLogoHolder>
        <PoweredBy
          id="powered-by-image"
          src={poweredByImage}
          alt="Powered By Indicio"
        />
      </StyledFormContainer>
    </CenteredContainer>
  )
}

export default RootPage

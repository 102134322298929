import Axios from 'axios'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import styled from 'styled-components'
import Cookies from 'universal-cookie'

import AdminRoute from './routes/AdminRoute'
import { check } from './UI/CanUser'
import EmailVerification from './UI/EmailVerification'
import FullPageSpinner from './UI/FullPageSpinner'
import { useNotification } from './UI/NotificationProvider'
import PoweredByImage from './assets/powered-by.png'
import SessionProvider from './UI/SessionProvider'
import { handleImageSrc } from './UI/util'
import RootPage from './routes/RootPage' //import RedirectToLogin from './routes/authLogin'
import Settings from './UI/Settings'

import store from './store'
import { setAuthors, setWaitingForAuthors } from './redux/authorsReducer'
import {
  setBasicMessages,
  clearBasicMessages,
} from './redux/basicMessagesReducer'
import {
  setCredentials,
  clearCredentialsState,
} from './redux/credentialsReducer'
import { setEmail } from './redux/emailReducer'
import {
  setHeldCredentials,
  clearHeldCredentialsState,
} from './redux/heldCredentialsReducer'
import {
  setContacts,
  setContactSelected,
  setConnection,
  setPendingConnections,
  setWaitingForContacts,
  setWaitingForPendingConnections,
  clearContactsState,
  setPingData,
  setPongData,
} from './redux/contactsReducer'
import {
  clearGovernanceState,
  setSelectedGovernance,
  setSelectedGovernanceRecord,
  setGovernanceEditorFiles,
  setGovernanceFiles,
  setFileUploaded,
} from './redux/governanceReducer'
import {
  setInvitations,
  setInvitationURL,
  setInvitationSelected,
  setWaitingForInvitations,
  clearInvitationsState,
} from './redux/invitationsReducer'
import {
  setLoggedIn,
  setLoggedInUserId,
  setLoggedInUsername,
  setLoggedInRoles,
} from './redux/loginReducer'
import {
  clearNotificationsState,
  setNotificationState,
} from './redux/notificationsReducer'
import {
  setPresentationReports,
  clearPresentationsState,
} from './redux/presentationsReducer'
import { setProofReports, clearProofsState } from './redux/proofsReducer'
import {
  setAgentConfig,
  setLogo,
  setOrganizationName,
  setSMTP,
  setTheme,
  setManifest,
  setSiteTitle,
  setRevocationAllowed,
  setDefaultSettings,
  clearSettingsState,
  setMaintenenceWindow,
} from './redux/settingsReducer'
import { setRoles } from './redux/rolesReducer'
import {
  getDID,
  getTAA,
  setPublicConnections,
  setSchemas,
  setTempLedgerSchemas,
  setSelectedLedgerSchema,
  setWalletDID,
  setVerificationDefinitions,
  clearSchemasState,
} from './redux/schemasReducer'
import { setAuthorInvMsgID } from './redux/endorserReducer'
import { setTransactions } from './redux/transactionsReducer'
import {
  setWebsocket,
  setReadyForWebsocketMessages,
} from './redux/websocketsReducer'

import {
  setJsonldContextFiles,
  // setJsonldContextSelected,
} from './redux/jsonldContextReducer'

import {
  setSubwallets,
  setScopedWallets,
  setApiKey,
  clearMultitenancyState,
  setWalletSettingsNeedCreation,
} from './redux/multitenancyReducer'

import './App.css'
import { PoweredBox, PoweredBy } from './UI/CommonStylesForms'

const Frame = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`
const Main = styled.main`
  flex: 9;
  padding: 30px;
`

function App() {
  const dispatch = useDispatch()
  const loginState = useSelector((state) => state.login)
  const notificationsState = useSelector((state) => state.notifications)
  const settingsState = useSelector((state) => state.settings)
  const websocketsState = useSelector((state) => state.websockets)
  const { notificationType, notificationMessage } = notificationsState
  const { websocket, readyForWebsocketMessages } = websocketsState
  const walletNeedsSettings = useSelector((state) => state.multitenancy)
    .walletNeedsSettings

  // const contextState = useSelector((state) => state.jsonldContexts)

  const setNotification = useNotification()

  let currentState
  const updateState = () => {
    //Update to current redux state
    currentState = store.getState()
  }

  // Websocket reference hook
  const controllerSocket = useRef()

  // (AmmonBurgi) Keeps track of loading processes. The useMemo is necessary to preserve list across re-renders.
  const loadingList = useMemo(() => [], [])
  const [appIsLoaded, setAppIsLoaded] = useState(false)

  // Styles to change array
  const [stylesArray, setStylesArray] = useState([])

  const cookies = new Cookies()
  const [session, setSession] = useState('')

  // const [retryCount, setRetryCount] = useState(0) // (eldersonar) Add when needed for keeping track of websocket reconnection attempts
  const retryDelay = useRef(1000) // initial delay time in ms

  const [focusedConnectionID, setFocusedConnectionID] = useState('')
  const [verifiedCredential, setVerifiedCredential] = useState('')
  const [pendingConnectionID, setPendingConnectionID] = useState('')

  // Transactions states
  const [authorRoleIsSet, setAuthorRoleIsSet] = useState(false)
  const [endorserInfoIsSet, setEndorserInfoIsSet] = useState(false)

  useEffect(() => {
    if ((notificationMessage, notificationType)) {
      setNotification(notificationMessage, notificationType)
      dispatch(clearNotificationsState())
    }
  }, [notificationMessage, notificationType])

  const checkSession = (isRefresh = false) => {
    console.log('Checking session')
    Axios({
      method: 'GET',
      url: '/api/check-session',
    })
      .then((res) => {
        // (eldersonar) Good session and user is logged in
        if (res.data.logged_in && res.data.id) {
          setSession(cookies.get('sessionId'))
          dispatch(setLoggedIn(true))
          dispatch(setLoggedInUserId(res.data.id))
          dispatch(setLoggedInUsername(res.data.username))
          dispatch(setLoggedInRoles(res.data.roles))
        }
        // (eldersonar) Good session and user is logged off
        else {
          if (res.data.refresh) {
            // (eldersonar) Force refresh on session regeneration
            window.location.reload()
          } else {
            setSession(cookies.get('sessionId'))
          }
        }
        if (isRefresh) {
          // (eldersonar) Trigger WS flow only on refresh or initial load
          initiateWebSocketFlow()
        }
      })
      .catch((error) => {
        console.log(error)
        // Unauthorized
        if (error.response.status === 401) {
          setAppIsLoaded(false)
          console.log('Unauthorized access')
          window.location.reload()
        } else {
          // (eldersonar) Potential to get a 500 error, but the chances are slim
          console.log('Test the unhandled response code here')
          console.log(error)
          setSession(cookies.get('sessionId'))
          setAppIsLoaded(false)
        }
      })
  }

  useEffect(() => {
    // Execute the code immediately
    checkSession(true)

    // Execute the code every minute
    const timerInterval = setInterval(() => {
      checkSession(false)
    }, 60000)

    return () => {
      clearInterval(timerInterval)
    }
  }, [loginState.loggedIn, readyForWebsocketMessages])

  // (JamesKEbert) Note: We may want to abstract the websockets out into a high-order component for better abstraction, especially potentially with authentication/authorization
  // Perform First Time Setup. Connect to Controller Server via Websockets
  useEffect(() => {
    const connect = () => {
      if (!controllerSocket.current) {
        let url = new URL('/api/ws', window.location.href)
        url.protocol = url.protocol.replace('http', 'ws')
        controllerSocket.current = new WebSocket(url.href)

        controllerSocket.current.onopen = () => {
          // setWebsocket(true)
          dispatch(setWebsocket(true))
          retryDelay.current = 1000 // reset delay time on successful connection
          // setRetryCount(0) // reset retry count on successful connection
        }

        controllerSocket.current.onclose = (event) => {
          console.log('closing ws')
          dispatch(setReadyForWebsocketMessages(false))
          dispatch(setWebsocket(true))

          // Reconnect on close
          setTimeout(() => {
            console.log('Reconnecting on close')
            // setRetryCount((prevCount) => prevCount + 1)
            controllerSocket.current = null // close previous socket instance
            connect() // reconnect
          }, retryDelay.current)

          // (eldersonar) Increase delay time for next retry. This will double to the maximum of 1 minute
          retryDelay.current = Math.min(retryDelay.current + 1000, 60000)
        }

        // Error Handler
        controllerSocket.current.onerror = (event) => {
          dispatch(
            setNotificationState({
              message: 'Client Error - Websockets',
              type: 'error',
            })
          )
        }

        // Receive new message from Controller Server
        controllerSocket.current.onmessage = (message) => {
          const parsedMessage = JSON.parse(message.data)

          messageHandler(
            parsedMessage.context,
            parsedMessage.type,
            parsedMessage.data
          )
        }
      }
    }

    if (session) {
      connect()
    }

    return () => {
      if (controllerSocket.current) {
        controllerSocket.current.close()
        dispatch(setReadyForWebsocketMessages(false))
      }
    }
  }, [session])

  // (eldersonar) Set-up site title. What about SEO? Will robots be able to read it?
  useEffect(() => {
    document.title = settingsState.siteTitle
  }, [settingsState.siteTitle])

  function initiateWebSocketFlow() {
    if (!readyForWebsocketMessages || loadingList.length !== 0) return

    // (eldersonar) Common websocket calls regardles of user's authentication status
    let commonCalls = [
      {
        context: 'SETTINGS',
        type: 'GET_MAINTENENCE_WINDOW',
        loadingProcess: 'MAINTENENCE_WINDOW',
      },
      { context: 'IMAGES', type: 'GET_LOGO', loadingProcess: 'LOGO' },
      { context: 'SETTINGS', type: 'GET_THEME', loadingProcess: 'THEME' },
      { context: 'SETTINGS', type: 'GET_MANIFEST', loadingProcess: 'MANIFEST' },
      {
        context: 'SETTINGS',
        type: 'GET_MAINTENENCE_WINDOW',
        loadingProcess: 'MAINTENENCE_WINDOW',
      },
      {
        context: 'SETTINGS',
        type: 'GET_ORGANIZATION',
        loadingProcess: 'ORGANIZATION',
      },
    ]

    // (eldersonar) Authenticated websocket calls
    const wsCallsAuthenticated = [
      ...commonCalls,
      {
        rule: 'presentations:read',
        context: 'PRESENTATIONS',
        type: 'GET_ALL',
        loadingProcess: 'PRESENTATIONS',
      },
      {
        rule: 'credentials:read',
        context: 'CREDENTIALS',
        type: 'GET_ALL',
        loadingProcess: 'CREDENTIALS',
      },
      {
        rule: 'roles:read',
        context: 'ROLES',
        type: 'GET_ALL',
        loadingProcess: 'ROLES',
      },
      {
        context: 'GOVERNANCE',
        type: 'GET_ALL_GOVERNANCE_EDITOR_FILES',
        loadingProcess: 'GOVERNANCE_EDITOR_FILES',
      },
      {
        context: 'GOVERNANCE',
        type: 'GET_GOVERNANCE_FILES',
        loadingProcess: 'GOVERNANCE_FILES',
      },
      {
        rule: 'schemas:read',
        context: 'SCHEMAS',
        type: 'GET_SCHEMAS',
        loadingProcess: 'SCHEMAS',
      },
      {
        rule: 'schemas:read',
        context: 'SCHEMAS',
        type: 'GET_TAA',
        loadingProcess: 'GET_TAA',
      },
      { context: 'SCHEMAS', type: 'GET_VERIFICATION_DEFINITIONS' },
      { context: 'SCHEMAS', type: 'GET_DID', loadingProcess: 'GET_DID' },
      {
        rule: 'credentials:read',
        context: 'HELD_CREDENTIALS',
        type: 'GET_ALL',
        loadingProcess: 'HELD_CREDENTIALS',
      },
      {
        rule: 'credentialsShared:read',
        context: 'PROOFS',
        type: 'GET_ALL',
        loadingProcess: 'PROOFS',
      },
      {
        context: 'JSONLD_CONTEXT',
        type: 'GET_ALL',
        loadingProcess: 'JSONLD_CONTEXT',
      },
      {
        rule: 'credentials:revoke',
        context: 'SETTINGS',
        type: 'GET_REVOCATION_ALLOWED',
        loadingProcess: 'REVOCATION_ALLOWED',
      },
      {
        rule: 'multitenancy:read',
        context: 'MULTITENANCY',
        type: 'GET_ALL_SUBWALLETS',
        loadingProcess: 'SUBWALLETS',
      },
      {
        context: 'MULTITENANCY',
        type: 'GET_SCOPED_WALLETS',
        loadingProcess: 'SCOPED_WALLETS',
      },
      {
        context: 'MULTITENANCY',
        type: 'CHECK_WALLET_SETTINGS',
        loadingProcess: 'WALLET_SETTINGS_NEED_CREATION',
      },
      {
        rule: 'settings:update',
        context: 'SETTINGS',
        type: 'GET_SMTP',
        loadingProcess: 'SMTP',
      },
      {
        rule: 'settings:update',
        context: 'TRANSACTIONS',
        type: 'GET_ALL',
        loadingProcess: 'TRANSACTIONS',
      },
    ]
    // TODO: optimize the setWalletSettingsNeedCreation checks
    // (eldersonar) Non-authenticated websocket calls
    const wsCallsNonAuthenticated = [
      ...commonCalls,
      {
        context: 'INVITATIONS',
        type: 'RECONNECT',
        loadingProcess: 'RECONNECT',
      },
      { context: 'SCHEMAS', type: 'GET_VERIFICATION_DEFINITIONS' },
    ]

    const executeWSCalls = (wsCalls) => {
      wsCalls.forEach(({ rule, context, type, loadingProcess }) => {
        if (!rule || check(rule)) {
          sendMessage(context, type, {})
          if (loadingProcess) {
            addLoadingProcess(loadingProcess)
          }
        }
      })
    }

    if (loginState.loggedIn) {
      executeWSCalls(wsCallsAuthenticated)
    } else {
      executeWSCalls(wsCallsNonAuthenticated)
    }
  }

  // Send a message to the Controller server
  function sendMessage(context, type, data = {}) {
    if (websocket && controllerSocket.current.readyState === WebSocket.OPEN) {
      controllerSocket.current.send(JSON.stringify({ context, type, data }))
    } else {
      console.log('the websocket is not ready')
    }
  }

  // Handle inbound messages
  const messageHandler = async (context, type, data = {}) => {
    updateState()

    try {
      console.log(
        `New Message with context: '${context}' and type: '${type}' with data:`,
        data
      )
      switch (context) {
        case 'AUTHORS':
          switch (type) {
            case 'AUTHORS':
              let currentAuthors = currentState.authors.authors
              let newAuthors = data.authorRecords
              let updatedAuthors = []

              // Loop through the new authors and check them against the existing array
              newAuthors.forEach((newAuthor) => {
                currentAuthors.forEach((currentAuthor, index) => {
                  if (
                    currentAuthor !== null &&
                    newAuthor !== null &&
                    currentAuthor.author_id === newAuthor.author_id
                  ) {
                    // If you find a match, delete the current copy from the current array
                    currentAuthors.splice(index, 1)
                  }
                })
                updatedAuthors.push(newAuthor)
              })

              // When you reach the end of the list of new authors, simply add any remaining current authors to the new array
              if (currentAuthors.length > 0) {
                updatedAuthors = [...updatedAuthors, ...currentAuthors]
              }

              // Sort the array by created_at, newest on top
              updatedAuthors.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setAuthors(updatedAuthors))
              dispatch(setWaitingForAuthors(false))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Messages Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break
        case 'BASIC_MESSAGES':
          switch (type) {
            case 'MESSAGES':
              let currentMessages = currentState.basicMessages.basicMessages
              let newMessages = data.basicMessages
              let updatedMessages = []

              // (mikekebert) Loop through the new credentials and check them against the existing array
              newMessages.forEach((newMessage) => {
                currentMessages.forEach((currentMessage, index) => {
                  if (
                    currentMessage !== null &&
                    newMessage !== null &&
                    currentMessage.id === newMessage.id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentMessages.splice(index, 1)
                  }
                })
                updatedMessages.push(newMessage)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newMessage.connection_id === focusedConnectionID) {
                  setFocusedConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new credentials, simply add any remaining current credentials to the new array
              if (currentMessages.length > 0)
                updatedMessages = [...updatedMessages, ...currentMessages]
              // (mikekebert) Sort the array by data created, newest on top
              updatedMessages.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setBasicMessages(updatedMessages))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Messages Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'INVITATIONS':
          switch (type) {
            case 'ACCEPTED_INVITATION':
              console.log('data is: ', data)
              dispatch(setAuthorInvMsgID(data.invitation.inv_msg_id))
              // If we have the endorser module and role is author:
              // Save the inv_msg_id to the redux store
              let currentInvitations = Array.isArray(currentState.invitations)
                ? [...currentState.invitations]
                : []
              currentInvitations.push(data)
              dispatch(setInvitations(currentInvitations))
              dispatch(setWaitingForInvitations(false))
              break

            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))

              break

            case 'INVITATIONS':
              dispatch(setInvitations(data))
              dispatch(setWaitingForInvitations(false))

              break

            case 'INVITATION_UPDATED':
              console.log('Invitation has been updated!')

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_id ===
                data.updatedInvitation.invitation_id
              ) {
                dispatch(setInvitationSelected(data.updatedInvitation))
              }
              break

            case 'INVITATION_DELETED':
              console.log(data)
              const index = currentState.invitations.findIndex(
                (v) => v.invitation_id === data
              )
              let alteredInvitations = [...currentState.invitations]
              alteredInvitations.splice(index, 1)
              dispatch(setInvitations(alteredInvitations))

              break

            case 'RECONNECT':
              if (data.success === true) {
                console.log('Successfully reconnected ws with connection_id')
                removeLoadingProcess('RECONNECT')
              } else {
                console.log(
                  "Warning: Couldn't reconnect ws with connection_id. No connection id is associated with this session"
                )
                removeLoadingProcess('RECONNECT')
              }
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break
        case 'EMAIL':
          switch (type) {
            case 'EMAIL_VERIFIED':
              dispatch(setEmail(data.email))
              dispatch(
                setNotificationState({
                  message: 'Email Invitation has been sent!',
                  type: 'notice',
                })
              )
              break
          }
          break
        case 'CONTACTS':
          switch (type) {
            case 'CONTACTS':
              dispatch(setContacts(data.contacts))
              dispatch(setWaitingForContacts(false))

              break

            case 'CONTACT':
              dispatch(setContactSelected(data.contact))

              break

            case 'CONNECTION':
              dispatch(setConnection(data.connection))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'CONTACT':
          switch (type) {
            case 'PING':
              dispatch(setPingData(data.pingData))

              break
            case 'PING_RESPONSE_RECEIVED':
              if (
                data.threadId ===
                currentState.contacts.pingData.pingData.threadId
              ) {
                const pongData = {
                  connectionId:
                    currentState.contacts.pingData.pingData.connectionId,
                }
                dispatch(setPongData(pongData))
              }
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CONNECTIONS':
          switch (type) {
            case 'PENDING_CONNECTIONS':
              dispatch(setPendingConnections(data.pendingConnections))
              dispatch(setWaitingForPendingConnections(false))

              break

            case 'CONNECTION':
              //(AmmonBurgi) Receives the most recent connection with state "active"
              dispatch(setConnection(data.connection))

              break

            case 'PUBLIC':
              // Return list of public connections
              dispatch(setPublicConnections(data.connections))
              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'OUT_OF_BAND':
          switch (type) {
            case 'INVITATION':
              dispatch(setInvitationURL(data.invitation_url))
              dispatch(setInvitationSelected(data))
              break

            case 'CONNECTION_REUSE':
              console.log(data.comment)

              const activeInvitation =
                currentState.invitations.invitationSelected
              if (
                activeInvitation.invitation_msg_id === data.invitation_msg_id
              ) {
                const message = `Connection reused for ${data.connection_id}`

                dispatch(
                  setNotificationState({ message: message, type: 'notice' })
                )
              }

              break

            case 'RECEIVED_INVITATION':
              const message = `Author received OOB invitation.`
              dispatch(
                setNotificationState({ message: message, type: 'notice' })
              )

              break

            default:
              setNotification(
                `Error - Unrecognized Websocket Message Type: ${type}`,
                'error'
              )
              break
          }
          break

        case 'ROLES':
          switch (type) {
            case 'ROLES':
              let currentRoles = currentState.roles.roles
              let newRoles = data.roles
              let updatedRoles = []
              // (mikekebert) Loop through the new roles and check them against the existing array
              newRoles.forEach((newRole) => {
                currentRoles.forEach((currentRole, index) => {
                  if (
                    currentRole !== null &&
                    newRole !== null &&
                    currentRole.role_id === newRole.role_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentRoles.splice(index, 1)
                  }
                })
                updatedRoles.push(newRole)
              })
              // (mikekebert) When you reach the end of the list of new roles, simply add any remaining current roles to the new array
              if (currentRoles.length > 0)
                updatedRoles = [...updatedRoles, ...currentRoles]

              dispatch(setRoles(updatedRoles))
              removeLoadingProcess('ROLES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'CREDENTIALS':
          switch (type) {
            case 'CREDENTIALS':
              let currentCredentials = currentState.credentials.credentials
              let newCredentials = data.credential_records
              let updatedCredentials = []
              // (mikekebert) Loop through the new credentials and check them against the existing array
              newCredentials.forEach((newCredential) => {
                currentCredentials.forEach((currentCredential, index) => {
                  if (
                    currentCredential !== null &&
                    newCredential !== null &&
                    currentCredential.credential_exchange_id ===
                      newCredential.credential_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentCredentials.splice(index, 1)
                  }
                })
                updatedCredentials.push(newCredential)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newCredential.connection_id === focusedConnectionID) {
                  setFocusedConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new credentials, simply add any remaining current credentials to the new array
              if (currentCredentials.length > 0)
                updatedCredentials = [
                  ...updatedCredentials,
                  ...currentCredentials,
                ]
              // (mikekebert) Sort the array by data created, newest on top
              updatedCredentials.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setCredentials(updatedCredentials))
              removeLoadingProcess('CREDENTIALS')
              break

            case 'CREDENTIAL_ISSUED':
              console.log('CREDENTIAL_ISSUED')
              console.log(data.state)
              dispatch(
                setNotificationState({
                  message: 'Credential was successfully issued',
                  type: 'notice',
                })
              )

              break

            case 'CREDENTIAL_REVOCATION':
              dispatch(
                setCredentials(
                  currentState.credentials.credentials.map((x) =>
                    x.credential_exchange_id ===
                    data.credentialRevocation.credential_exchange_id
                      ? data.credentialRevocation
                      : x
                  )
                )
              )

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'HELD_CREDENTIALS':
          switch (type) {
            case 'HELD_CREDENTIALS':
              let currentCredentials =
                currentState.heldCredentials.heldCredentials
              let newCredentials = data.credential_records
              let updatedCredentials = []
              // (mikekebert) Loop through the new credentials and check them against the existing array
              newCredentials.forEach((newCredential) => {
                currentCredentials.forEach((currentCredential, index) => {
                  if (
                    currentCredential !== null &&
                    newCredential !== null &&
                    currentCredential.credential_exchange_id ===
                      newCredential.credential_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentCredentials.splice(index, 1)
                  }
                })
                updatedCredentials.push(newCredential)
                // // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                // if (newCredential.connection_id === focusedConnectionID) {
                //   setFocusedConnectionID('')
                // }
              })
              // (mikekebert) When you reach the end of the list of new credentials, simply add any remaining current credentials to the new array
              if (currentCredentials.length > 0)
                updatedCredentials = [
                  ...updatedCredentials,
                  ...currentCredentials,
                ]
              // (mikekebert) Sort the array by data created, newest on top
              updatedCredentials.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setHeldCredentials(updatedCredentials))
              removeLoadingProcess('HELD_CREDENTIALS')
              break

            case 'HELD_CREDENTIAL_RECORD_DELETED':
              console.log('HELD_CREDENTIAL_RECORD_DELETED')
              const currentHeldCredentials =
                currentState.heldCredentials.heldCredentials
              const index = currentHeldCredentials.findIndex(
                (v) => v.credential_id === data
              )
              let alteredHeldCredentials = [...currentHeldCredentials]
              alteredHeldCredentials.splice(index, 1)
              dispatch(setHeldCredentials(alteredHeldCredentials))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'PRESENTATIONS':
          switch (type) {
            case 'CREDENTIAL_VERIFIED':
              setPendingConnectionID(data.connection_id)
              setVerifiedCredential(data.revealed_attrs)

              dispatch(
                setNotificationState({
                  message: 'Credential was successfully verified',
                  type: 'notice',
                })
              )
              break

            case 'VERIFICATION_FAILED':
              setVerifiedCredential(null)
              break

            case 'PRESENTATION_REPORTS':
              let currentPresentations =
                currentState.presentations.presentationReports
              let newPresentations = data.presentation_reports
              let updatedPresentations = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newPresentations.forEach((newPresentation) => {
                currentPresentations.forEach((currentPresentation, index) => {
                  if (
                    currentPresentation !== null &&
                    newPresentation !== null &&
                    currentPresentation.presentation_exchange_id ===
                      newPresentation.presentation_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentPresentations.splice(index, 1)
                  }
                })
                updatedPresentations.push(newPresentation)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newPresentation.connection_id === pendingConnectionID) {
                  setPendingConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentPresentations.length > 0)
                updatedPresentations = [
                  ...updatedPresentations,
                  ...currentPresentations,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedPresentations.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setPresentationReports(updatedPresentations))

              removeLoadingProcess('PRESENTATIONS')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'PROOFS':
          switch (type) {
            case 'PROOF_REPORTS':
              let currentProofs = currentState.proofs.proofReports
              let newProofs = data.proof_reports
              let updatedProofs = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newProofs.forEach((newPresentation) => {
                currentProofs.forEach((currentPresentation, index) => {
                  if (
                    currentPresentation !== null &&
                    newPresentation !== null &&
                    currentPresentation.presentation_exchange_id ===
                      newPresentation.presentation_exchange_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentProofs.splice(index, 1)
                  }
                })
                updatedProofs.push(newPresentation)
                // (mikekebert) We also want to make sure to reset any pending connection IDs so the modal windows don't pop up automatically
                if (newPresentation.connection_id === pendingConnectionID) {
                  setPendingConnectionID('')
                }
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentProofs.length > 0)
                updatedProofs = [...updatedProofs, ...currentProofs]
              // (mikekebert) Sort the array by date created, newest on top
              updatedProofs.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setProofReports(updatedProofs))

              removeLoadingProcess('PROOFS')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'JSONLD_CONTEXT':
          switch (type) {
            case 'JSONLD_CONTEXT_FILES':
              let currentJsonldContexts =
                currentState.jsonldContexts.jsonldContextFiles
              let newJsonldContexts = data.contextFiles
              let updatedJsonldContexts = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newJsonldContexts.forEach((newContext) => {
                currentJsonldContexts.forEach((currentContext, index) => {
                  if (
                    currentContext !== null &&
                    newContext !== null &&
                    currentContext.context_file_id ===
                      newContext.context_file_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentJsonldContexts.splice(index, 1)
                  }
                })
                updatedJsonldContexts.push(newContext)
              })

              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentJsonldContexts.length > 0)
                updatedJsonldContexts = [
                  ...updatedJsonldContexts,
                  ...currentJsonldContexts,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedJsonldContexts.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setJsonldContextFiles(updatedJsonldContexts))
              removeLoadingProcess('JSONLD_CONTEXT')
              break

            case 'JSONLD_CONTEXT_DELETED':
              const currentJsonldContextArray =
                currentState.jsonldContexts.jsonldContextFiles
              const index = currentJsonldContextArray.findIndex(
                (v) => v.context_file_id === data
              )
              let alteredContextFiles = [...currentJsonldContextArray]
              alteredContextFiles.splice(index, 1)
              dispatch(setJsonldContextFiles(alteredContextFiles))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SCHEMAS':
          switch (type) {
            case 'GET_DID':
              dispatch(getDID(data))
              removeLoadingProcess('GET_DID')
              break

            case 'CREATED_SCHEMAS':
              let currentAddedSchemas = currentState.schemas.tempLedgerSchemas
              let newAddedSchemas = data.newSchemas
              let updatedAddedSchemas = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newAddedSchemas.forEach((newSchema) => {
                currentAddedSchemas.forEach((currentSchema, index) => {
                  if (
                    currentSchema !== null &&
                    newSchema !== null &&
                    currentSchema.transaction_id === newSchema.transaction_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentAddedSchemas.splice(index, 1)
                  }
                })
                updatedAddedSchemas.push(newSchema)
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentAddedSchemas.length > 0)
                updatedAddedSchemas = [
                  ...updatedAddedSchemas,
                  ...currentAddedSchemas,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedAddedSchemas.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setTempLedgerSchemas(updatedAddedSchemas))
              break

            case 'GET_TAA':
              dispatch(getTAA(data))
              removeLoadingProcess('GET_TAA')
              break

            case 'CREATE_DID':
              console.log('CREATE_DID')
              console.log(data)
              dispatch(setWalletDID(data))
              break

            case 'LEDGER_SCHEMA':
              dispatch(setSelectedLedgerSchema(data))
              break

            case 'SCHEMAS':
              let currentSchemas = currentState.schemas.schemas
              let newSchemas = data.schemas
              let updatedSchemas = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newSchemas.forEach((newSchema) => {
                currentSchemas.forEach((currentSchema, index) => {
                  if (
                    currentSchema !== null &&
                    newSchema !== null &&
                    currentSchema.schema_id === newSchema.schema_id
                  ) {
                    // (mikekebert) If you find a match, delete the current copy from the current array
                    currentSchemas.splice(index, 1)
                  }
                })
                updatedSchemas.push(newSchema)
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentSchemas.length > 0)
                updatedSchemas = [...updatedSchemas, ...currentSchemas]
              // (mikekebert) Sort the array by date created, newest on top
              updatedSchemas.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setSchemas(updatedSchemas))
              removeLoadingProcess('SCHEMAS')
              break

            case 'VERIFICATION_DEFINITIONS':
              let currentVerificationDefinitions =
                currentState.schemas.verificationDefinitions
              let newVerificationDefinitions = data.verificationDefinitions
              let updatedVerificationDefinitions = []

              // (mikekebert) Loop through the new presentation and check them against the existing array
              newVerificationDefinitions.forEach((newSchema) => {
                currentVerificationDefinitions.forEach(
                  (currentSchema, index) => {
                    if (
                      currentSchema !== null &&
                      newSchema !== null &&
                      currentSchema.id === newSchema.id
                    ) {
                      // (mikekebert) If you find a match, delete the current copy from the current array
                      currentVerificationDefinitions.splice(index, 1)
                    }
                  }
                )
                updatedVerificationDefinitions.push(newSchema)
              })
              // (mikekebert) When you reach the end of the list of new presentations, simply add any remaining current presentations to the new array
              if (currentVerificationDefinitions.length > 0)
                updatedVerificationDefinitions = [
                  ...updatedVerificationDefinitions,
                  ...currentVerificationDefinitions,
                ]
              // (mikekebert) Sort the array by date created, newest on top
              updatedVerificationDefinitions.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(
                setVerificationDefinitions(updatedVerificationDefinitions)
              )
              break

            case 'VERIFICATION_DEFINITION_DELETED':
              const currentVerificationDefinitionsArray =
                currentState.schemas.verificationDefinitions
              const index = currentVerificationDefinitionsArray.findIndex(
                (v) => v.verification_label === data
              )
              let alteredVerificationDefinitions = [
                ...currentVerificationDefinitionsArray,
              ]
              alteredVerificationDefinitions.splice(index, 1)
              dispatch(
                setVerificationDefinitions(alteredVerificationDefinitions)
              )
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SERVER':
          switch (type) {
            case 'NOTIFICATIONS':
              dispatch(setNotificationState(data))

              break

            case 'WEBSOCKET_READY':
              dispatch(setReadyForWebsocketMessages(true))

              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'SETTINGS':
          switch (type) {
            case 'AGENT_CONFIG':
              localStorage.setItem('agentConfig', JSON.stringify(data))
              dispatch(setAgentConfig(data))
              break

            case 'SETTINGS_THEME':
              // Writing the recent theme to a local storage
              const newTheme = data
              const stringMessageTheme = JSON.stringify(newTheme)
              window.localStorage.setItem('recentTheme', stringMessageTheme)
              dispatch(setTheme(newTheme))
              removeLoadingProcess('THEME')
              break

            case 'SETTINGS_MANIFEST':
              dispatch(setManifest(data))
              removeLoadingProcess('MANIFEST')
              break

            case 'LOGO':
              dispatch(setLogo(handleImageSrc(data.image.data)))
              removeLoadingProcess('LOGO')
              break

            case 'SETTINGS_ORGANIZATION':
              dispatch(setOrganizationName(data.organizationName))
              dispatch(setSiteTitle(data.title))
              removeLoadingProcess('ORGANIZATION')
              break

            case 'SETTINGS_SMTP':
              dispatch(setSMTP(data))
              removeLoadingProcess('SMTP')
              break

            case 'REVOCATION_ALLOWED':
              dispatch(setRevocationAllowed(data))
              removeLoadingProcess('REVOCATION_ALLOWED')
              break

            case 'SETTINGS_MAINTENENCE_WINDOW':
              // Cast to DATE type before sending anywhere
              if (typeof data.value?.startDateTime === 'string') {
                data.value.startDateTime = new Date(data.value.startDateTime)
              }
              if (typeof data.value?.endDateTime === 'string') {
                data.value.endDateTime = new Date(data.value.endDateTime)
              }
              // Set it in Redux (Also sets in local storage)
              dispatch(setMaintenenceWindow(data.value))
              removeLoadingProcess('MAINTENENCE_WINDOW')
              break

            case 'SETTINGS_UPDATED':
              dispatch(setWalletSettingsNeedCreation(false))
              removeLoadingProcess('SETTINGS_UPDATED')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'IMAGES':
          switch (type) {
            case 'IMAGE_LIST':
              dispatch(setLogo(handleImageSrc(data.image.data)))

              removeLoadingProcess('IMAGES')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'ORGANIZATION':
          switch (type) {
            case 'ORGANIZATION_NAME':
              dispatch(setOrganizationName(data[0].value.name))

              removeLoadingProcess('ORGANIZATION')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )

              break
          }
          break

        case 'MULTITENANCY':
          switch (type) {
            case 'SUBWALLET_CREATED':
              dispatch(setSubwallets(data))
              break

            case 'SUBWALLETS':
              let subwalletList = currentState.multitenancy.subwallets
              let newSubwalletList = data.subwallets

              // (eldersonar) I had issues with Mike's filtering approach so I came up with this
              const updatedSubwalletList = newSubwalletList.filter(
                (newSubwallet) => {
                  return !subwalletList.some(
                    (currentSubwallet) =>
                      currentSubwallet &&
                      currentSubwallet.wallet_id === newSubwallet.wallet_id
                  )
                }
              )

              const finalSubwalletList = [
                ...subwalletList,
                ...updatedSubwalletList,
              ]

              dispatch(setSubwallets(finalSubwalletList))
              removeLoadingProcess('SUBWALLETS')
              break

            case 'WALLET_SETTINGS_NEED_CREATION':
              dispatch(setWalletSettingsNeedCreation(data.settingsNeeded))
              if (data.settingsNeeded) {
                dispatch(setDefaultSettings(data.allSettings))
              }
              removeLoadingProcess('WALLET_SETTINGS_NEED_CREATION')
              break

            case 'APIKEY_CREATED':
              dispatch(setApiKey(data))
              break

            case 'SCOPED_WALLETS':
              dispatch(setScopedWallets(data.subwallets))
              removeLoadingProcess('SCOPED_WALLETS')
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'TRANSACTIONS':
          switch (type) {
            case 'TRANSACTIONS':
              let currentTransactions = currentState.transactions.transactions
              let newTransactions = data.transactions
              let updatedTransactions = []

              // Loop through the new transactions and check them against the existing array
              newTransactions.forEach((newTransaction) => {
                currentTransactions.forEach((currentTransaction, index) => {
                  if (
                    currentTransaction !== null &&
                    newTransaction !== null &&
                    currentTransaction.transaction_id ===
                      newTransaction.transaction_id
                  ) {
                    // If you find a match, delete the current copy from the current array
                    currentTransactions.splice(index, 1)
                  }
                })
                updatedTransactions.push(newTransaction)
              })

              // When you reach the end of the list of new transactions, simply add any remaining current transactions to the new array
              if (currentTransactions.length > 0) {
                updatedTransactions = [
                  ...updatedTransactions,
                  ...currentTransactions,
                ]
              }

              // Sort the array by created_at, newest on top
              updatedTransactions.sort((a, b) =>
                a.created_at < b.created_at ? 1 : -1
              )

              dispatch(setTransactions(updatedTransactions))
              removeLoadingProcess('TRANSACTIONS')

              break

            case 'ROLE_SET':
              console.log('ROLE_SET')
              console.log(data)
              setAuthorRoleIsSet(true)
              break

            case 'ENDORSER_INFO_SET':
              console.log('ENDORSER_INFO_SET')
              console.log(data)
              setEndorserInfoIsSet(true)
              break

            case 'CONTACT':
              dispatch(setContactSelected(data.contact))

              break

            case 'CONNECTION':
              dispatch(setConnection(data.connection))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        case 'GOVERNANCE':
          switch (type) {
            case 'GOVERNANCE_EDITOR_FILES':
              let currentGovernanceEditorFiles =
                currentState.governance.governanceEditorFiles
              let newGovernanceEditorFiles = data.governanceFiles

              // (eldersonar) I had issues with Mike's filtering approach so I came up with this
              const updatedGovernanceEditorFiles = newGovernanceEditorFiles.filter(
                (newFile) => {
                  return !currentGovernanceEditorFiles.some(
                    (currentFile) =>
                      currentFile && currentFile.id === newFile.id
                  )
                }
              )

              const finalUpdatedFiles = [
                ...currentGovernanceEditorFiles,
                ...updatedGovernanceEditorFiles,
              ]

              dispatch(setGovernanceEditorFiles(finalUpdatedFiles))
              removeLoadingProcess('GOVERNANCE_EDITOR_FILES')
              break

            case 'GOVERNANCE_EDITOR_FILE':
              console.log(data.governanceEditorFile)

              dispatch(setSelectedGovernanceRecord(data.governanceEditorFile))
              dispatch(setSelectedGovernance(data.governanceEditorFile.file))
              //(RomanStepanyan) This state allows governance components to know when to re-wright selected governance state
              //(RomanStepanyan) This state can potentinally be replaced with more optimal code
              dispatch(setFileUploaded(true))
              break

            case 'GOVERNANCE_FILES':
              let currentGovernanceFiles =
                currentState.governance.governanceFiles
              let newGovernanceFiles = data.governanceFiles
              let updatedGovernanceFiles = []

              // (mikekebert) Loop through the new governance files and check them against the existing array
              newGovernanceFiles.forEach((newGovernanceFile) => {
                currentGovernanceFiles.forEach(
                  (currentGovernanceFile, index) => {
                    if (
                      currentGovernanceFile !== null &&
                      newGovernanceFile !== null &&
                      currentGovernanceFile.governance_id ===
                        newGovernanceFile.governance_id
                    ) {
                      // (mikekebert) If you find a match, delete the current copy from the current array
                      currentGovernanceFiles.splice(index, 1)
                    }
                  }
                )
                updatedGovernanceFiles.push(newGovernanceFile)

                if (currentGovernanceFiles.length > 0) {
                  updatedGovernanceFiles = [
                    ...updatedGovernanceFiles,
                    ...currentGovernanceFiles,
                  ]
                }

                // (mikekebert) Sort the array by data created, newest on top
                updatedGovernanceFiles.sort((a, b) =>
                  a.created_at < b.created_at ? 1 : -1
                )

                dispatch(setGovernanceFiles(updatedGovernanceFiles))
              })
              removeLoadingProcess('GOVERNANCE_FILES')
              break

            case 'GOVERNANCE_FILE_DELETED':
              const currentGovernanceFilesArray =
                currentState.governance.governanceFiles
              const index = currentGovernanceFilesArray.findIndex(
                (v) => v.governance_id === data
              )
              let alteredGovernanceFiles = [...currentGovernanceFilesArray]
              alteredGovernanceFiles.splice(index, 1)
              dispatch(setGovernanceFiles(alteredGovernanceFiles))

              break

            case 'GOVERNANCE_FROM_SOURCE':
              dispatch(setSelectedGovernance(data.apiGovernance))
              dispatch(setFileUploaded(true))
              break

            case 'GOVERNANCE_WARNING':
              dispatch(setNotificationState({ message: data, type: 'warning' }))
              break

            default:
              dispatch(
                setNotificationState({
                  message: `Error - Unrecognized Websocket Message Type: ${type}`,
                  type: 'error',
                })
              )
              break
          }
          break

        default:
          dispatch(
            setNotificationState({
              message: `Error - Unrecognized Websocket Message Type: ${context}`,
              type: 'error',
            })
          )

          break
      }
    } catch (error) {
      console.log('Error caught:', error)
      console.log(context)
      console.log(type)
      dispatch(
        setNotificationState({
          message: 'Client Error - Websocket',
          type: 'error',
        })
      )
    }
  }

  function addLoadingProcess(process) {
    loadingList.push(process)
  }

  function removeLoadingProcess(process) {
    const index = loadingList.indexOf(process)
    if (index > -1) {
      loadingList.splice(index, 1)
    }

    if (loadingList.length === 0) {
      setAppIsLoaded(true)
    }
  }

  // (eldersonar) TODO: inspect to see if this is needed
  // function setUpUser(id, username, roles) {
  //   dispatch(setLoggedInUserId(id))
  //   dispatch(setLoggedInUsername(username))
  //   dispatch(setLoggedInRoles(roles))
  // }

  // Update theme state locally
  const updateTheme = (update) => {
    updateState()
    return dispatch(setTheme({ ...currentState.settings.theme, ...update }))
  }

  // Update theme in the database
  const saveTheme = () => {
    sendMessage('SETTINGS', 'SET_THEME', settingsState.theme)
  }

  const saveMaintenenceWindow = () => {
    // You need to call getState() to get the current state after the component has updated it.
    sendMessage(
      'SETTINGS',
      'SET_MAINTENENCE_WINDOW',
      store.getState().settings.maintenenceWindow
    )
  }

  //(RomanStepanyan) Removing all styles from an array of styles to desible undo button
  const clearStylesArray = () => {
    setStylesArray([])
  }

  const addStylesToArray = (key) => {
    let position = stylesArray.indexOf(key)
    // if cannot find indexOf style
    if (!~position) {
      setStylesArray((currentArray) => [...currentArray, `${key}`])
    }
  }

  const removeStylesFromArray = (undoKey) => {
    // Removing a style from an array of styles
    let index = stylesArray.indexOf(undoKey)
    if (index > -1) {
      stylesArray.splice(index, 1)
      setStylesArray(stylesArray)
    }
  }

  // Undo theme change
  const undoStyle = (undoKey) => {
    const recentTheme = JSON.parse(localStorage.getItem('recentTheme'))
    updateState()

    if (undoKey !== undefined) {
      for (let key in recentTheme)
        if ((key = undoKey)) {
          const undo = { [`${key}`]: recentTheme[key] }
          return dispatch(setTheme({ ...currentState.settings.theme, ...undo }))
        }
    }
  }

  // Logout and redirect
  const handleLogout = async (history) => {
    // Clear all the states
    dispatch(clearBasicMessages())
    dispatch(clearCredentialsState())
    dispatch(clearHeldCredentialsState())
    dispatch(clearContactsState())
    dispatch(clearGovernanceState())
    dispatch(clearInvitationsState())
    dispatch(clearSettingsState())
    dispatch(clearSchemasState())
    dispatch(clearPresentationsState())
    dispatch(clearProofsState())
    dispatch(clearMultitenancyState())

    try {
      // Call the server to handle Keycloak logout
      const response = await Axios.post('/auth/logout')

      if (response.data.success) {
        // If logout was successful, redirect
        if (history !== undefined) {
          history.push('/login')
        } else {
          window.location.href = '/login'
        }
      } else {
        console.error('Logout failed:', response.data.message)
      }
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  if (
    (loginState.loggedIn && !appIsLoaded) ||
    (!loginState.loggedIn && !appIsLoaded)
  ) {
    // Show the spinner while the app is loading
    return <FullPageSpinner />
  } else if (!loginState.loggedIn && appIsLoaded) {
    return (
      <SessionProvider logout={handleLogout}>
        <Router>
          <Switch>
            <Route
              path="/email-verification"
              render={({ match, history }) => {
                return (
                  <>
                    <Frame id="app-frame">
                      <Main>
                        <EmailVerification
                          history={history}
                          sendRequest={sendMessage}
                          messageHandler={messageHandler}
                          websocket={websocket}
                          readyForMessages={readyForWebsocketMessages}
                        />
                      </Main>
                    </Frame>
                    <PoweredBox>
                      <PoweredBy
                        src={PoweredByImage}
                        alt="Powered By Indicio"
                      />
                    </PoweredBox>
                  </>
                )
              }}
            />
            <Route exact path="/" component={RootPage} />
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </SessionProvider>
    )
  } else {
    // loggedIn and appIsLoaded
    return (
      <SessionProvider logout={handleLogout}>
        <Router>
          <Switch>
            <Route
              path="/admin"
              render={({ history }) => {
                if (walletNeedsSettings) {
                  return (
                    <Frame id="app-frame">
                      <Main>
                        <Settings
                          loadingList={loadingList}
                          needsSMTP={false}
                          updateTheme={updateTheme}
                          saveTheme={saveTheme}
                          walletNeedsSettings={walletNeedsSettings}
                          saveMaintenenceWindow={saveMaintenenceWindow}
                          undoStyle={undoStyle}
                          stylesArray={stylesArray}
                          addStylesToArray={addStylesToArray}
                          clearStylesArray={clearStylesArray}
                          removeStylesFromArray={removeStylesFromArray}
                          sendRequest={sendMessage}
                          saveAllButton={true}
                        />
                      </Main>
                    </Frame>
                  )
                }
                return (
                  <AdminRoute
                    history={history}
                    handleLogout={handleLogout}
                    sendMessage={sendMessage}
                    updateTheme={updateTheme}
                    saveTheme={saveTheme}
                    saveMaintenenceWindow={saveMaintenenceWindow}
                    undoStyle={undoStyle}
                    stylesArray={stylesArray}
                    clearStylesArray={clearStylesArray}
                    addStylesToArray={addStylesToArray}
                    removeStylesFromArray={removeStylesFromArray}
                    verifiedCredential={verifiedCredential}
                    setVerifiedCredential={setVerifiedCredential}
                    authorRoleIsSet={authorRoleIsSet}
                    endorserInfoIsSet={endorserInfoIsSet}
                  />
                )
              }}
            />
            {/* Redirect to root if no route match is found */}
            <Route render={() => <Redirect to="/admin" />} />
          </Switch>
        </Router>
      </SessionProvider>
    )
  }
}

export default App

import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsFaviconForm(props) {
  const theme = useTheme()
  const {
    SettingsHeader,
    Form,
    PreviewWrapper,
    PreviewContainer,
    Input,
    UploadImageBtn,
    DefaultImageSubmitBtn,
    ImageWrapper,
    PreviewImage,
    faviconFileName,
    uploaded,
    handleFaviconSubmit,
    handleDefaultFaviconSelect,
    faviconSelectHandler,
    selectedFavicon,
  } = props
  return (
    <>
      <SettingsHeader>Update favicon.ico</SettingsHeader>
      <IconHelp
        data-tip
        data-for="faviconTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="faviconTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          Organization favicon is used to
          <br />
          represents a website in web browsers.
          <br />
          The max size is 200KB.
        </span>
      </ReactTooltip>
      <PreviewWrapper>
        <PreviewContainer>
          <Form onSubmit={handleFaviconSubmit}>
            <Input
              type="file"
              accept=".ico"
              onChange={faviconSelectHandler}
              id="input-update-favicon-choose-file"
            ></Input>
            <UploadImageBtn
              type="submit"
              id="button-update-favicon-upload"
              disabled={!selectedFavicon}
            >
              Upload
            </UploadImageBtn>
          </Form>
          <Form onSubmit={handleDefaultFaviconSelect}>
            <DefaultImageSubmitBtn
              type="submit"
              id="button-update-default-favicon-upload"
            >
              Select Default Favicon
            </DefaultImageSubmitBtn>
          </Form>
        </PreviewContainer>
        {selectedFavicon && faviconFileName && !uploaded && (
          <ImageWrapper>
            <PreviewImage src={selectedFavicon} alt="Selected Logo" />
          </ImageWrapper>
        )}
      </PreviewWrapper>
    </>
  )
}

export default SettingsFaviconForm

import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

function Proof(props) {
  const contacts = useSelector((state) => state.contacts.contacts)
  const presExId = props.presExId
  const presentationReports = useSelector((state) => state.proofs.proofReports)

  let presentationSelected = ''

  for (let i = 0; i < presentationReports.length; i++) {
    if (presentationReports[i].presentation_exchange_id === presExId) {
      presentationSelected = presentationReports[i]
      break
    }
  }

  // (AmmonBurgi) Find connection and give the selected presentation a contact_label.
  for (let i = 0; i < contacts.length; i++) {
    if (
      contacts[i].Connections[0].connection_id ===
      presentationSelected.connection_id
    ) {
      presentationSelected.contact_label = contacts[i].label
      break
    }
  }

  if (presentationSelected !== '') {
    let displayAttributes = ''
    let attributes = {}
    const requestName = presentationSelected.presentation_request?.name || ''

    if (presentationSelected.presentation) {
      const presentationRequestedProof =
        presentationSelected.presentation?.by_format?.pres?.indy
          ?.requested_proof
      if (
        presentationRequestedProof?.self_attested_attrs ||
        presentationRequestedProof?.revealed_attrs
      ) {
        attributes = {
          ...presentationRequestedProof.self_attested_attrs,
          ...presentationRequestedProof.revealed_attrs,
        }
      }
    }

    if (
      attributes[Object.keys(attributes)[0]] &&
      attributes[Object.keys(attributes)[0]].raw !== null &&
      attributes[Object.keys(attributes)[0]].raw !== undefined
    ) {
      for (const attribute in attributes) {
        attributes[attribute] = attributes[attribute].raw
      }
    }

    const verifiedAttributes = Object.keys(attributes).map((key) => (
      <AttributeRow key={key}>
        <th>
          {key.charAt(0).toUpperCase() + key.replaceAll('_', ' ').slice(1)}:{' '}
        </th>
        <td>{attributes[key]}</td>
      </AttributeRow>
    ))

    if (presentationSelected.presentation) {
      displayAttributes = (
        <>
          <h2>Presentation Attributes</h2>
          <AttributeTable>
            <tbody>{verifiedAttributes}</tbody>
          </AttributeTable>
        </>
      )
    }

    return (
      <div id="contact">
        <PageHeader
          title={'Proof to ' + presentationSelected.contact_label || ''}
        />
        <PageSection>
          <h2>Proof Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact Label:</th>
                <td>{presentationSelected.contact_label || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection ID:</th>
                <td>{presentationSelected.connection_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Initiator:</th>
                <td>{presentationSelected.initiator || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Presentation Exchange ID:</th>
                <td>{presentationSelected.presentation_exchange_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Request Name:</th>
                <td>{requestName || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Role:</th>
                <td>{presentationSelected.role || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>State:</th>
                <td>{presentationSelected.state.replaceAll('_', ' ') || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Thread ID:</th>
                <td>{presentationSelected.thread_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Updated:</th>
                <td>
                  {DateTime.fromISO(
                    presentationSelected.presentation_updated_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                </td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Created:</th>
                <td>
                  {DateTime.fromISO(
                    presentationSelected.presentation_created_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          {displayAttributes}
        </PageSection>
      </div>
    )
  } else {
    return (
      <div id="contact">
        <PageHeader title={'Presentation request'} />
        <PageSection>
          <h2>Presentation Information</h2>
        </PageSection>
      </div>
    )
  }
}

export default Proof

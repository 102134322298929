import { createStore, combineReducers } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'

import basicMessagesReducer from './redux/basicMessagesReducer'
import contactsReducer from './redux/contactsReducer'
import credentialsReducer from './redux/credentialsReducer'
import emailReducer from './redux/emailReducer'
import heldCredentialsReducer from './redux/heldCredentialsReducer'
import invitationsReducer from './redux/invitationsReducer'
import governanceReducer from './redux/governanceReducer'
import loginReducer from './redux/loginReducer'
import multitenancyReducer from './redux/multitenancyReducer'
import notificationsReducer from './redux/notificationsReducer'
import presentationsReducer from './redux/presentationsReducer'
import proofsReducer from './redux/proofsReducer'
import settingsReducer from './redux/settingsReducer'
import rolesReducer from './redux/rolesReducer'
import schemasReducer from './redux/schemasReducer'
import websocketsReducer from './redux/websocketsReducer'
import jsonldContextsReducer from './redux/jsonldContextReducer'

// Endorser Module
import endorserReducer from './redux/endorserReducer'
import transactionsReducer from './redux/transactionsReducer'
import authorsReducer from './redux/authorsReducer'

const rootReducer = combineReducers({
  authors: authorsReducer,
  basicMessages: basicMessagesReducer,
  contacts: contactsReducer,
  credentials: credentialsReducer,
  email: emailReducer,
  endorser: endorserReducer,
  heldCredentials: heldCredentialsReducer,
  invitations: invitationsReducer,
  governance: governanceReducer,
  login: loginReducer,
  multitenancy: multitenancyReducer,
  notifications: notificationsReducer,
  presentations: presentationsReducer,
  proofs: proofsReducer,
  settings: settingsReducer,
  schemas: schemasReducer,
  roles: rolesReducer,
  transactions: transactionsReducer,
  websockets: websocketsReducer,
  jsonldContexts: jsonldContextsReducer,
})

export default createStore(
  rootReducer
  //Comment out the following line when pushing to production
  // composeWithDevTools()
)

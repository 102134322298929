import styled from 'styled-components'

// Text alignments
export const H3 = styled.h3`
  margin: 5px 0;
`

export const TextAlignCenter = styled.div`
  text-align: center;
`

export const TextAlignRight = styled.div`
  text-align: right;
`

export const TextAlignLeft = styled.div`
  text-align: left;
`
export const AttributeTable = styled.table`
  margin-bottom: 1em;
  margin-left: 20px;
  border-collapse: collapse;
`
export const NavLink = styled.a`
  display: block;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: bold;
  color: #cf9f14;
  border: none;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

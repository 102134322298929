import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsLogoForm(props) {
  const theme = useTheme()
  const {
    SettingsHeader,
    Form,
    PreviewWrapper,
    PreviewContainer,
    Input,
    UploadImageBtn,
    DefaultImageSubmitBtn,
    ImageWrapper,
    PreviewImage,
    logoFileName,
    uploaded,
    handleLogoSubmit,
    handleDefaultLogoSelect,
    logoSelectHandler,
    selectedLogo,
  } = props
  return (
    <>
      <SettingsHeader>Change Logo</SettingsHeader>
      <IconHelp
        data-tip
        data-for="logoTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="logoTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          Organization logo is used in
          <br />
          the UI and email messages.
          <br />
          The max size is 500KB.
        </span>
      </ReactTooltip>
      <PreviewWrapper>
        <PreviewContainer>
          <Form onSubmit={handleLogoSubmit}>
            <Input
              type="file"
              accept=".jpeg, .jpg, .png, .gif, .webp"
              onChange={logoSelectHandler}
              id="input-change-logo-choose-file"
            ></Input>
            <UploadImageBtn
              type="submit"
              id="button-change-logo-upload"
              disabled={!selectedLogo}
            >
              Upload
            </UploadImageBtn>
          </Form>
          <Form onSubmit={handleDefaultLogoSelect}>
            <DefaultImageSubmitBtn
              type="submit"
              id="button-update-default-logo-upload"
            >
              Select Default Logo
            </DefaultImageSubmitBtn>
          </Form>
        </PreviewContainer>
        {selectedLogo && logoFileName && !uploaded && (
          <ImageWrapper>
            <PreviewImage src={selectedLogo} alt="Selected Logo" />
          </ImageWrapper>
        )}
      </PreviewWrapper>
    </>
  )
}

export default SettingsLogoForm

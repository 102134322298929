import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import { useTheme } from 'styled-components'

function SettingsLogo512Form(props) {
  const theme = useTheme()
  const {
    SettingsHeader,
    Form,
    PreviewWrapper,
    PreviewContainer,
    Input,
    UploadImageBtn,
    DefaultImageSubmitBtn,
    ImageWrapper,
    PreviewImage,
    logo512FileName,
    uploaded,
    handleLogo512Submit,
    handleDefaultLogo512Select,
    logo512SelectHandler,
    selectedLogo512,
  } = props
  return (
    <>
      <SettingsHeader>Change Logo 512x512</SettingsHeader>
      <IconHelp
        data-tip
        data-for="logo512Tip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="logo512Tip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          logo192.png is the icon used to show on the tab
          <br />
          of mobile device. logo512.png is part of PWA.
          <br />
          The max size is 200KB.
        </span>
      </ReactTooltip>
      <PreviewWrapper>
        <PreviewContainer>
          <Form onSubmit={handleLogo512Submit}>
            <Input
              type="file"
              accept=".png"
              onChange={logo512SelectHandler}
              id="input-512x512-change-logo-choose-file"
            ></Input>
            <UploadImageBtn
              type="submit"
              id="button-512x512-change-logo-upload"
              disabled={!selectedLogo512}
            >
              Upload
            </UploadImageBtn>
          </Form>
          <Form onSubmit={handleDefaultLogo512Select}>
            <DefaultImageSubmitBtn
              type="submit"
              id="button-update-default-logo512-upload"
            >
              Select Default Logo512
            </DefaultImageSubmitBtn>
          </Form>
        </PreviewContainer>
        {selectedLogo512 && logo512FileName && !uploaded && (
          <ImageWrapper>
            <PreviewImage src={selectedLogo512} alt="Selected Logo" />
          </ImageWrapper>
        )}
      </PreviewWrapper>
    </>
  )
}

export default SettingsLogo512Form

import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import PageHeader from './PageHeader'
import PageSection from './PageSection'
import ThemeSettingsForm from './SettingsThemeForm'
import SettingsMaintananceForm from './SettingsMaintananceForm'
import SettingsSmtpForm from './SettingsSmtpForm'
import SettingsManifestForm from './SettingsManifestForm'
import SettingsFaviconForm from './SettingsFaviconForm'
import SettingsLogo512Form from './SettingsLogo512Form'
import SettingsLogo192Form from './SettingsLogo192Form'
import SettingsLogoForm from './SettingsLogoForm'
import SettingsOrganizationDetailsForm from './SettingsOrganizationDetailsForm'

import { setNotificationState } from '../redux/notificationsReducer'

import {
  setLogo,
  setOrganizationName,
  setManifest,
  setSiteTitle,
} from '../redux/settingsReducer'

import { handleImageSrc } from './util'

const SettingsHeader = styled.h2`
  display: inline-block;
  margin-right: 10px;
`

const SaveBtn = styled.button`
  width: 80px;
  background: ${(props) => props.theme.primary_color};
  padding: 10px;
  color: ${(props) => props.theme.text_light};
  border: none;
  float: right;
  box-shadow: ${(props) => props.theme.drop_shadow};
  :hover {
    cursor: pointer;
  }
`

const UploadImageBtn = styled.button`
  max-height: 18px;
`
const BlockInput = styled.input`
  display: block;
  margin-bottom: 15px;
`

const Input = styled.input``

const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

const PreviewWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1;
`
const PreviewContainer = styled.div`
  margin-right: 60px;
`
const ImageWrapper = styled.div`
  flex-shrink: 0;
`
const PreviewImage = styled.img`
  max-width: 62px;
  max-height: 100%;
  object-fit: contain;
`

function Settings(props) {
  const dispatch = useDispatch()
  const settingsState = useSelector((state) => state.settings)

  // File state
  const [selectedFavicon, setSelectedFile] = useState('')
  const [selectedLogo, setSelectedLogo] = useState('')
  const [selectedLogo192, setSelectedLogo192] = useState('')
  const [selectedLogo512, setSelectedLogo512] = useState('')
  const [logoFileName, setLogoFileName] = useState('Choose file')
  const [faviconFileName, setFaviconFileName] = useState('Choose file')
  const [logo192FileName, setLogo192FileName] = useState('Choose file')
  const [logo512FileName, setLogo512FileName] = useState('Choose file')

  const [uploaded, setUploaded] = useState(false)

  // SMTP input references
  const smtpForm = useRef(null)
  const organizationForm = useRef(null)
  const manifestDetailsForm = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    e.target.reset()
  }

  // Setting the style to the default value
  const undoStyle = (key) => {
    props.undoStyle(key)
    props.removeStylesFromArray(key)
  }

  // Save style settings
  function saveStyle() {
    props.saveTheme()
    props.clearStylesArray()
  }

  // Save SMTP settings
  const handleSMTP = (e) => {
    e.preventDefault()

    const form = new FormData(smtpForm.current)

    if (!form.get('host') || !form.get('mailUsername') || !form.get('email')) {
      dispatch(
        setNotificationState({
          message:
            'Host, Mail Username, User Email and Password are required fields. See the tooltip for more info.',
          type: 'error',
        })
      )
    } else {
      const smtpConfigs = {
        host: form.get('host'),
        port: form.get('port'),
        auth: {
          email: form.get('email'),
          pass: form.get('password'),
          mailUsername: form.get('mailUsername'),
        },
      }

      props.sendRequest('SETTINGS', 'SET_SMTP', smtpConfigs)

      // (eldersonar) Wait for 2 seconds to update the SMTP object
      setTimeout(() => props.sendRequest('SETTINGS', 'GET_SMTP'), 2000)
    }
  }

  // Save manifest settings
  const handleManifest = (e) => {
    e.preventDefault()

    const form = new FormData(manifestDetailsForm.current)

    const manifestConfigs = {
      short_name: form.get('short_manifest_name'),
      name: form.get('manifest_name'),
      theme_color: form.get('theme_color'),
      background_color: form.get('background_color'),
    }

    console.log(manifestConfigs)
    props.sendRequest('SETTINGS', 'SET_MANIFEST', manifestConfigs)
  }

  // Save organization details
  const handleOrganizationDetails = (e) => {
    e.preventDefault()
    const form = new FormData(
      organizationForm.current || settingsState.defaultSettings.organization
    )
    const name = {
      organizationName: form.get('organizationName'),
      title: form.get('siteTitle'),
    }
    props.sendRequest('SETTINGS', 'SET_ORGANIZATION', name)
  }

  // Logo upload

  // Setting up file and file name
  let logoSelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.5Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 500300) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.5Mb',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo(reader.result)
      }
      reader.readAsDataURL(file)

      setLogoFileName(event.target.files[0].name)
      setUploaded(false)
    } else {
      setLogoFileName('')
      setSelectedLogo('')
    }
  }

  const handleLogoSubmit = async (e) => {
    e.preventDefault()
    if (selectedLogo) {
      const image = {
        name: logoFileName,
        type: 'logo',
        image: selectedLogo,
      }

      props.sendRequest('IMAGES', 'SET_LOGO', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogoSelect = async (e) => {
    e.preventDefault()
    setSelectedLogo(
      handleImageSrc(settingsState.defaultSettings.logos.logo.image.data)
    )
    setLogoFileName(settingsState.defaultSettings.logos.logo.name)
  }

  // Favicon upload

  // Setting up file and file name
  let faviconSelectHandler = (event) => {
    const file = event.target.files[0]
    console.log(file)

    // The image is over 0.2Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 200200) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.2Mb.',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedFile(reader.result)
      }
      reader.readAsDataURL(file)

      setFaviconFileName(event.target.files[0].name)
      setUploaded(false)
    } else {
      setFaviconFileName('')
      setSelectedFile('')
    }
  }

  const handleFaviconSubmit = async (e) => {
    e.preventDefault()
    if (selectedFavicon) {
      console.log(selectedFavicon)
      const image = {
        name: faviconFileName,
        type: 'icon',
        image: selectedFavicon,
      }

      props.sendRequest('IMAGES', 'SET_FAVICON', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultFaviconSelect = async (e) => {
    e.preventDefault()
    setSelectedFile(
      handleImageSrc(settingsState.defaultSettings.logos.favicon.image.data)
    )
    setFaviconFileName(settingsState.defaultSettings.logos.favicon.name)
  }

  // Logo192 upload
  let logo192SelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.1Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 100100) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.1Mb.',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo192(reader.result)
      }
      reader.readAsDataURL(file)

      setLogo192FileName(event.target.files[0].name)
      setUploaded(false)
    } else {
      setLogo192FileName('')
      setSelectedLogo192('')
    }
  }

  const handleLogo192Submit = async (e) => {
    e.preventDefault()
    if (selectedLogo192) {
      const image = {
        name: logo192FileName,
        type: 'icon',
        image: selectedLogo192,
      }

      props.sendRequest('IMAGES', 'SET_LOGO192', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogo192Select = async (e) => {
    e.preventDefault()
    setSelectedLogo192(
      handleImageSrc(settingsState.defaultSettings.logos.logo192.image.data)
    )
    setLogo192FileName(settingsState.defaultSettings.logos.logo192.name)
  }

  // Logo512 upload
  let logo512SelectHandler = (event) => {
    const file = event.target.files[0]

    // The image is over 0.2Mb size. It will grow 33% (1mb) as it's converted to base64
    if (file && file.size > 200200) {
      dispatch(
        setNotificationState({
          message: 'The image is over 0.2Mb',
          type: 'error',
        })
      )
      return
    }

    if (file) {
      // Converting the image to base64
      const reader = new FileReader()
      reader.onloadend = function () {
        setSelectedLogo512(reader.result)
      }
      reader.readAsDataURL(file)

      setLogo512FileName(event.target.files[0].name)
      setUploaded(false)
    } else {
      setLogo512FileName('')
      setSelectedLogo512('')
    }
  }

  const handleLogo512Submit = async (e) => {
    e.preventDefault()
    if (selectedLogo512) {
      const image = {
        name: logo512FileName,
        type: 'icon',
        image: selectedLogo512,
      }

      props.sendRequest('IMAGES', 'SET_LOGO512', image)
    } else {
      dispatch(
        setNotificationState({
          message: 'The image is not selected.',
          type: 'error',
        })
      )
    }
  }

  const handleDefaultLogo512Select = async (e) => {
    e.preventDefault()
    setSelectedLogo512(
      handleImageSrc(settingsState.defaultSettings.logos.logo512.image.data)
    )
    setLogo512FileName(settingsState.defaultSettings.logos.logo512.name)
  }

  // TODO: optimize data validation, especially images
  const handleSaveAllSettings = (e) => {
    e.preventDefault()

    const getImageData = (imageSelector, fileName, type) => {
      if (imageSelector) {
        return { name: fileName, type, image: imageSelector }
      } else {
        dispatch(
          setNotificationState({
            message: 'The image is not selected.',
            type: 'error',
          })
        )
        return null
      }
    }

    let form = new FormData(manifestDetailsForm.current)
    const manifest = {
      short_name: form.get('short_manifest_name'),
      name: form.get('manifest_name'),
      theme_color: form.get('theme_color'),
      background_color: form.get('background_color'),
    }
    manifestDetailsForm.current.reset()
    form = new FormData(
      organizationForm.current || settingsState.defaultSettings.organization
    )
    const org = {
      organizationName: form.get('organizationName'),
      title: form.get('siteTitle'),
    }
    organizationForm.current.reset()
    const logoImage = getImageData(selectedLogo, logoFileName, 'logo')
    const logo192Image = getImageData(selectedLogo192, logo192FileName, 'icon')
    const logo512Image = getImageData(selectedLogo512, logo512FileName, 'icon')
    const faviconImage = getImageData(selectedFavicon, faviconFileName, 'icon')
    saveStyle()
    props.loadingList.push('SETTINGS_UPDATED')
    const allSettings = {
      manifest,
      organization: org,
      logo: logoImage,
      logo192: logo192Image,
      logo512: logo512Image,
      favicon: faviconImage,
    }
    props.sendRequest('SETTINGS', 'SET_ALL_SETTINGS', allSettings)
    dispatch(setManifest(manifest))
    dispatch(setOrganizationName(org.organizationName))
    dispatch(setSiteTitle(org.title))
    if (logoImage) {
      dispatch(setLogo(logoImage.image))
    }
  }

  const DefaultImageSubmitBtn = ({
    type,
    id,
    shouldRender = props.saveAllButton,
    children,
  }) => {
    return shouldRender ? (
      <UploadImageBtn type={type} id={id}>
        {children}
      </UploadImageBtn>
    ) : null
  }

  return (
    <div id="settings">
      {props.saveAllButton ? (
        <PageHeader
          title={
            'Settings: First Time Wallet Setup (Admin or Super-Admin Only)'
          }
        />
      ) : (
        <PageHeader title={'Settings'} />
      )}
      <PageSection>
        <SettingsOrganizationDetailsForm
          SettingsHeader={SettingsHeader}
          Form={Form}
          BlockInput={BlockInput}
          SaveBtn={SaveBtn}
          handleSubmit={handleSubmit}
          settingsState={settingsState}
          organizationForm={organizationForm}
          handleOrganizationDetails={handleOrganizationDetails}
        />
      </PageSection>

      <PageSection>
        <SettingsLogoForm
          SettingsHeader={SettingsHeader}
          Form={Form}
          PreviewWrapper={PreviewWrapper}
          PreviewContainer={PreviewContainer}
          Input={Input}
          UploadImageBtn={UploadImageBtn}
          DefaultImageSubmitBtn={DefaultImageSubmitBtn}
          ImageWrapper={ImageWrapper}
          PreviewImage={PreviewImage}
          logoFileName={logoFileName}
          uploaded={uploaded}
          handleLogoSubmit={handleLogoSubmit}
          handleDefaultLogoSelect={handleDefaultLogoSelect}
          logoSelectHandler={logoSelectHandler}
          selectedLogo={selectedLogo}
        />
      </PageSection>

      <PageSection>
        <SettingsLogo192Form
          SettingsHeader={SettingsHeader}
          Form={Form}
          PreviewWrapper={PreviewWrapper}
          PreviewContainer={PreviewContainer}
          Input={Input}
          UploadImageBtn={UploadImageBtn}
          DefaultImageSubmitBtn={DefaultImageSubmitBtn}
          ImageWrapper={ImageWrapper}
          PreviewImage={PreviewImage}
          logo192FileName={logo192FileName}
          uploaded={uploaded}
          handleLogo192Submit={handleLogo192Submit}
          handleDefaultLogo192Select={handleDefaultLogo192Select}
          logo192SelectHandler={logo192SelectHandler}
          selectedLogo192={selectedLogo192}
        />
      </PageSection>

      <PageSection>
        <SettingsLogo512Form
          SettingsHeader={SettingsHeader}
          Form={Form}
          PreviewWrapper={PreviewWrapper}
          PreviewContainer={PreviewContainer}
          Input={Input}
          UploadImageBtn={UploadImageBtn}
          DefaultImageSubmitBtn={DefaultImageSubmitBtn}
          ImageWrapper={ImageWrapper}
          PreviewImage={PreviewImage}
          logo512FileName={logo512FileName}
          uploaded={uploaded}
          handleLogo512Submit={handleLogo512Submit}
          handleDefaultLogo512Select={handleDefaultLogo512Select}
          logo512SelectHandler={logo512SelectHandler}
          selectedLogo512={selectedLogo512}
        />
      </PageSection>

      <PageSection>
        <SettingsFaviconForm
          SettingsHeader={SettingsHeader}
          Form={Form}
          PreviewWrapper={PreviewWrapper}
          PreviewContainer={PreviewContainer}
          Input={Input}
          UploadImageBtn={UploadImageBtn}
          DefaultImageSubmitBtn={DefaultImageSubmitBtn}
          ImageWrapper={ImageWrapper}
          PreviewImage={PreviewImage}
          faviconFileName={faviconFileName}
          uploaded={uploaded}
          handleFaviconSubmit={handleFaviconSubmit}
          handleDefaultFaviconSelect={handleDefaultFaviconSelect}
          faviconSelectHandler={faviconSelectHandler}
          selectedFavicon={selectedFavicon}
        />
      </PageSection>

      <PageSection>
        <SettingsManifestForm
          SettingsHeader={SettingsHeader}
          Form={Form}
          BlockInput={BlockInput}
          SaveBtn={SaveBtn}
          handleSubmit={handleSubmit}
          settingsState={settingsState}
          manifestDetailsForm={manifestDetailsForm}
          handleManifest={handleManifest}
        />
      </PageSection>
      {!props.walletNeedsSettings && (
        <PageSection>
          <SettingsSmtpForm
            SettingsHeader={SettingsHeader}
            Form={Form}
            BlockInput={BlockInput}
            SaveBtn={SaveBtn}
            handleSubmit={handleSubmit}
            settingsState={settingsState}
            handleSMTP={handleSMTP}
            smtpForm={smtpForm}
          />
        </PageSection>
      )}

      {!props.walletNeedsSettings && (
        <PageSection>
          <SettingsMaintananceForm
            SettingsHeader={SettingsHeader}
            saveMaintenenceWindow={props.saveMaintenenceWindow}
          />
        </PageSection>
      )}

      <PageSection>
        <ThemeSettingsForm
          setNotificationState={props.setNotificationState}
          saveStyle={saveStyle}
          undoStyle={undoStyle}
          handleSubmit={handleSubmit}
          stylesArray={props.stylesArray}
          updateTheme={props.updateTheme}
          addStylesToArray={props.addStylesToArray}
        />
      </PageSection>
      {props.saveAllButton && (
        <SaveBtn onClick={handleSaveAllSettings} id="button-theme-save-all">
          Save All
        </SaveBtn>
      )}
    </div>
  )
}

export default Settings

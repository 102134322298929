import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { CanUser } from '../../../UI/CanUser'
import PageHeader from '../../../UI/PageHeader'
import PageSection from '../../../UI/PageSection'
import FormGovernanceFileDelete from './FormFileDelete'

import { setNotificationState } from '../../../redux/notificationsReducer'

import { H3 } from '../../../UI/CommonStyles'
import { TextAlignCenter } from '../Styles/CommonStyles'
import {
  DataTable,
  DataRow,
  DataHeader,
  DataCell,
  IconCell,
  IconRemove,
  IconRefresh,
} from '../Styles/CommonStylesTables'

const Checkbox = styled.input``
const SubmitFormBtn = styled.button``
const Input = styled.input``
const Form = styled.form`
  overflow: hidden;
  margin-bottom: 10px;
`

function GovernanceFiles(props) {
  const dispatch = useDispatch()
  const governanceState = useSelector((state) => state.governance)
  const { notificationType } = useSelector((state) => state.notifications)
  const [
    deleteGovernanceFileModalIsOpen,
    setDeleteGovernanceFileModalIsOpen,
  ] = useState(false)
  const [goverananceFileId, setGovernanceFileId] = useState('')
  const [index, setIndex] = useState(false)

  const files = governanceState.governanceFiles

  const governanceForm = useRef(null)
  const governancePath = useRef(null)

  const [selectedFile, setSelectedFile] = useState({})
  // (eldersonar) This state allows us to control the value of the governance selection (from files)
  const [selectedFileHTML, setSelectedFileHTML] = useState(null)

  const addGovernance = (e) => {
    e.preventDefault()
    const form = new FormData(governanceForm.current)
    const goverancePath = form.get('governance_path')

    //(RomanStepanyan) Checking if a URL starts with http or https
    const urlMatch =
      goverancePath.match('http://') || goverancePath.match('https://')
    if (urlMatch && urlMatch.index === 0) {
      props.sendRequest(
        'GOVERNANCE',
        'SET_GOVERNANCE_FILE_VIA_API',
        goverancePath
      )
    } else {
      return dispatch(
        setNotificationState({
          message:
            "URL address is not correct and governance file can't be selected",
          type: 'error',
        })
      )
    }
    governanceForm.current.reset()
  }

  let governanceSelectHandler = (event) => {
    const file = event.target.files[0]

    // (eldersonar) Save HTML element to a local state
    setSelectedFileHTML(event.target)

    if (file) {
      const fileReader = new FileReader()
      fileReader.readAsText(event.target.files[0], 'UTF-8')
      fileReader.onload = (e) => {
        try {
          setSelectedFile(JSON.parse(e.target.result))

          dispatch(
            setNotificationState({
              message: `The governance file, ${file.name}, has been selected. Next, click on "Upload".`,
              type: 'notice',
            })
          )
        } catch (error) {
          dispatch(
            setNotificationState({
              message: `Invalid JSON file: ${file.name} contains syntax errors`,
              type: 'error',
            })
          )
          document.getElementById('input-choose-files').value = ''
        }
      }
    }
  }

  // (eldersonar) Handle governance injection based on the format type
  const handleGovernanceUpload = async (e) => {
    e.preventDefault()
    if (selectedFile.id && selectedFile.format) {
      props.sendRequest('GOVERNANCE', 'SET_GOVERNANCE_FILE', {
        id: selectedFile.id,
        file: selectedFile,
      })

      dispatch(
        setNotificationState({
          message: `This file is a draft file, and should not be used for production`,
          type: 'warning',
        })
      )

      resetSelectGovernance()
    } else {
      dispatch(
        setNotificationState({
          message: `This file is not governance file or it is missing core data`,
          type: 'error',
        })
      )
    }
  }

  // (eldersonar) Reset the value of the governance file selection HTML element
  function resetSelectGovernance(value) {
    if (selectedFileHTML) {
      setSelectedFileHTML((prev) => {
        const updatedState = prev
        updatedState.value = null
        return updatedState
      })
    }
  }

  useEffect(() => {
    if (notificationType === 'notice') {
      // (Simon): Temporary solution. Closing all/any modals on success
      closeDeleteModal()
    } else if (notificationType === 'error') {
      setIndex((prevIndex) => setIndex(prevIndex + 1))
    }
  }, [notificationType])

  const closeDeleteModal = () => setDeleteGovernanceFileModalIsOpen(false)

  const deleteGovernanceFile = () => {
    setDeleteGovernanceFileModalIsOpen(true)
  }

  const handleSubmit = (e) => {
    console.log('on handlesubmit')
    e.preventDefault()
    e.target.reset()
  }

  const refreshGovernanceFile = (file) => {
    props.sendRequest('GOVERNANCE', 'REFRESH_GOVERNANCE_VERSION', {
      file,
    })
  }

  const handleActiveFlagChange = (event) => {
    const fileId = event.target.id
    const fileChecked = event.target.checked
    props.sendRequest('GOVERNANCE', 'UPDATE_GOVERNANCE_FILE_FLAG', {
      fileId,
      fileChecked,
    })
  }

  let fileRows = null

  if (files) {
    fileRows = files.map((element) => {
      let fileId = ''
      let fileName = ''
      let fileVersion = ''
      let fileFormat = ''
      let fileDescription = ''

      if (element) {
        if (element.file.id) {
          fileId = element.file.id
        }
        if (element.file.name) {
          fileName = element.file.name
        }
        if (element.file.version) {
          fileVersion = element.file.version
        }
        if (element.file.format) {
          fileFormat = element.file.format
        }
        if (element.file.description) {
          fileDescription = element.file.description
        }
      }

      return (
        <DataRow key={element.file.id}>
          <DataCell>{fileName}</DataCell>
          <DataCell>{fileVersion}</DataCell>
          <DataCell>{fileFormat}</DataCell>
          <DataCell>{fileDescription}</DataCell>
          {element.file.uri ? (
            <IconCell
              onClick={() => {
                refreshGovernanceFile(element.file)
              }}
            >
              <IconRefresh alt="Refresh" />
            </IconCell>
          ) : (
            <IconCell />
          )}
          <IconCell>
            <Checkbox
              type="checkbox"
              checked={element.active}
              id={fileId}
              onChange={handleActiveFlagChange}
            ></Checkbox>
          </IconCell>
          <IconCell
            onClick={() => {
              deleteGovernanceFile()
              setGovernanceFileId(fileId)
            }}
          >
            <IconRemove alt="Remove" />
          </IconCell>
        </DataRow>
      )
    })
  }

  return (
    <>
      <div id="files">
        <PageHeader title={'Governance Files'} />
        <PageSection>
          <H3>Get governance from files</H3>
          <Form onSubmit={handleGovernanceUpload}>
            <Input
              id="input-choose-files"
              type="file"
              accept=".json"
              onChange={governanceSelectHandler}
            ></Input>
            <SubmitFormBtn
              id="button-upload-governance-from-files"
              type="submit"
            >
              Upload
            </SubmitFormBtn>
          </Form>
          <hr></hr>
          <Form onSubmit={handleSubmit} ref={governanceForm}>
            <H3>Get governance via API</H3>
            <Input
              id="input-governance-via-api"
              type="url"
              name="governance_path"
              ref={governancePath}
              placeholder="https://mrg.com/governance.json"
              required
            />
            <SubmitFormBtn
              id="button-upload-governance-from-api"
              type="submit"
              onClick={addGovernance}
            >
              Upload
            </SubmitFormBtn>
          </Form>
        </PageSection>
        <PageSection>
          <DataTable>
            <thead>
              <DataRow>
                <DataHeader>Name</DataHeader>
                <DataHeader>Version</DataHeader>
                <DataHeader>Format</DataHeader>
                <DataHeader>
                  <TextAlignCenter>Description</TextAlignCenter>
                </DataHeader>
                <CanUser
                  perform="governanceFiles:set"
                  yes={() => (
                    <DataHeader>
                      <TextAlignCenter>Refresh</TextAlignCenter>
                    </DataHeader>
                  )}
                />
                <CanUser
                  perform="governanceFiles:set"
                  yes={() => (
                    <DataHeader>
                      <TextAlignCenter>Active</TextAlignCenter>
                    </DataHeader>
                  )}
                />
                <CanUser
                  perform="governanceFiles:delete"
                  yes={() => (
                    <DataHeader>
                      <TextAlignCenter>Remove</TextAlignCenter>
                    </DataHeader>
                  )}
                />
              </DataRow>
            </thead>
            <tbody>{fileRows ? fileRows : ''}</tbody>
          </DataTable>
        </PageSection>
        <FormGovernanceFileDelete
          sendRequest={props.sendRequest}
          error={index}
          goverananceFileId={goverananceFileId}
          deleteGovernanceFileModalIsOpen={deleteGovernanceFileModalIsOpen}
          closeDeleteModal={closeDeleteModal}
        />
      </div>
    </>
  )
}

export default GovernanceFiles

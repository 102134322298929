import { useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { IconHelp } from './CommonStylesTables'
import styled, { useTheme } from 'styled-components'

const SMTPHeader = styled.h3`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
`

function SettingsSmtpForm(props) {
  const {
    SettingsHeader,
    Form,
    BlockInput,
    SaveBtn,
    handleSubmit,
    settingsState,
    handleSMTP,
    smtpForm,
  } = props

  let smtpConf = settingsState.smtp

  const host = useRef(null)
  const mailUsername = useRef(null)
  const userEmail = useRef(null)
  const userPassword = useRef(null)
  const port = useRef(null)

  const theme = useTheme()
  return (
    <>
      <SettingsHeader>SMTP Configuration</SettingsHeader>
      <IconHelp
        data-tip
        data-for="smtpTip"
        data-delay-hide="250"
        data-multiline="true"
        alt="Help"
      />
      <ReactTooltip
        id="smtpTip"
        effect="solid"
        type="info"
        backgroundColor={theme.primary_color}
      >
        <span>
          The SMTP configuration is used for sending
          <br />
          new user and password reset emails.
        </span>
      </ReactTooltip>
      <Form onSubmit={handleSubmit} ref={smtpForm}>
        <SMTPHeader>Host</SMTPHeader>
        <IconHelp
          data-tip
          data-for="smtpHostTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpHostTip"
          effect="solid"
          type="info"
          backgroundColor={theme.primary_color}
        >
          <span>
            This is the hostname or IP address to connect to.
            <br />
            <br />
            Required field.
          </span>
        </ReactTooltip>
        <BlockInput
          name="host"
          ref={host}
          defaultValue={smtpConf ? (smtpConf.host ? smtpConf.host : '') : ''}
          id="input-smtp-host"
        />
        <SMTPHeader>Mail Username</SMTPHeader>
        <IconHelp
          data-tip
          data-for="smtpMailUsernameTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpMailUsernameTip"
          effect="solid"
          type="info"
          backgroundColor={theme.primary_color}
        >
          <span>
            This is the username.
            <br />
            For Gmail accounts mail username must be the same as the user email.
            <br />
            <br />
            Required field.
          </span>
        </ReactTooltip>
        <BlockInput
          name="mailUsername"
          ref={mailUsername}
          defaultValue={
            smtpConf ? (smtpConf.auth ? smtpConf.auth.mailUsername : '') : ''
          }
          id="input-smtp-mail-username"
        />
        <SMTPHeader>User email</SMTPHeader>
        <IconHelp
          data-tip
          data-for="smtpUserEmailTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpUserEmailTip"
          effect="solid"
          type="info"
          backgroundColor={theme.primary_color}
        >
          <span>
            This is the user email address.
            <br />
            <br />
            Required field.
          </span>
        </ReactTooltip>
        <BlockInput
          name="email"
          ref={userEmail}
          defaultValue={
            smtpConf ? (smtpConf.auth ? smtpConf.auth.email : '') : ''
          }
          id="input-smtp-email"
        />
        <SMTPHeader>User password</SMTPHeader>
        <IconHelp
          data-tip
          data-for="smtpPasswordTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpPasswordTip"
          effect="solid"
          type="info"
          backgroundColor={theme.primary_color}
        >
          <span>
            This is the password for the user email.
            <br />
            For Gmail SMTP cofiguration use the APP Password.
            <br />
            <br />
            Optional field.
          </span>
        </ReactTooltip>
        <BlockInput
          type="password"
          name="password"
          ref={userPassword}
          defaultValue={smtpConf ? (smtpConf.auth ? '************' : '') : ''}
          id="input-smtp-password"
        />
        <SMTPHeader>Port</SMTPHeader>
        <IconHelp
          data-tip
          data-for="smtpPortTip"
          data-delay-hide="250"
          data-multiline="true"
          alt="Help"
        />
        <ReactTooltip
          id="smtpPortTip"
          effect="solid"
          type="info"
          backgroundColor={theme.primary_color}
        >
          <span>
            This is the port to connect to.
            <br />
            It defaults to 587 if "secure" is false or 465 if true.
            <br />
            <br />
            Optional field.
          </span>
        </ReactTooltip>
        <BlockInput
          name="port"
          placeholder="587 or 465"
          ref={port}
          defaultValue={smtpConf ? (smtpConf.port ? smtpConf.port : '') : ''}
          id="input-smtp-port"
        />
        <SaveBtn onClick={handleSMTP} id="button-smtp-save">
          Save
        </SaveBtn>
      </Form>
    </>
  )
}

export default SettingsSmtpForm
